import { DeleteFilled } from "@ant-design/icons";
import { Button, Col, Row, Space, Spin, Typography } from "antd";
import { useEffect } from "react";
import { WhitelistNamespace } from "../../../../_shared/namespaces/whitelist";
import { WhitelistWrapper } from "./index.styled";
const {Text} = Typography;
interface WhitelistComponentProps{
  allWhitelists: WhitelistNamespace.Whitelist[];
  loading: boolean;
  handleNewGroup : ()=>void
  handleManageGroup : (data?: any)=>void
  initComponent : ()=>void
  
  
}
export const WhitelistComponent = (props: WhitelistComponentProps) => {
    const { handleNewGroup, handleManageGroup, initComponent, allWhitelists, loading} = props;

    useEffect(() => {
      initComponent()
    }, []);
    
    
    const renderListData = allWhitelists.map((data, key)=>(
      <>
      <Row key={key}  className="row-list-item">
        <Col  span={8}>
          <Text className="">{data.name}</Text>
        </Col>
        <Col  span={8}>
          <Text className="">{data.percentage}%</Text>
        </Col>
        <Col  span={8}>
          <Space className="btn-container">
            <Button onClick={()=>handleManageGroup(data)} shape="round" className="withdraw-btn">
              Manage
            </Button>
            <Button shape="round" type="text" icon={<DeleteFilled />} className="delete-btn"/>
          </Space>
        </Col>
      </Row>
      </>
    ))
    return (
          <WhitelistWrapper>
              <div className="hint" >
            <Text>
              Issue a percentage bonuse to specific addresses
            </Text>
            </div>
            <Space className="top-bar" align="center">
                
                <Button loading={loading} onClick={handleNewGroup} shape="round" className="new-group-btn">
                  New Group
                </Button>
            </Space>
            <Space className="list-container" direction="vertical">
              <Row>
                <Col  span={8}>
                  <Text className="muted">Group Name</Text>
                </Col>
                <Col  span={8}>
                  <Text className="muted">Bonus</Text>
                </Col>
              </Row>
              {renderListData}
              {!loading && allWhitelists.length === 0 && <span className="muted" style={{textAlign:'center', display:'block'}}>You have not added any bonus</span>}
              <Spin style={{display: loading?'block':'none'}} />
            </Space>
          </WhitelistWrapper>
    );
  };