import { ThemeContext, WalletConnectorContext } from "../../../contexts";
// import { MenuOutlined } from "@ant-design/icons";
import { Typography,  Button, Switch, Space } from "antd";
import { useContext } from "react";
import { StyledHeader} from "./index.styled";
import { useNavigate } from "react-router-dom";
import { shorternAddress } from "../../../_shared";

const { Text } = Typography;


interface MainHeaderProps {
    text?: string;
}
export const MainHeader = (props: MainHeaderProps) => {
    const { } = props;
    const {setThemeType, themeType} = useContext(ThemeContext);
    const {connect, disconnect, address} = useContext(WalletConnectorContext);
    const onChange = (checked: boolean) => {
        if(!setThemeType) return
        if(checked){
            setThemeType('light')
        }else{
            setThemeType('dark')
        }
      };

      const navigate = useNavigate();


    return (<StyledHeader>
                <div className="logo-container">
                    {/* <Button className="menu-button" type="text" >
                        <MenuOutlined />
                    </Button> */}
                    {/* <img src="/logo-sm.svg" alt="...." /> */}
                    <a href="/projects">
                    <img src="/pulsesac_logo.svg" alt="...." /> 
                    </a>
                    <a href="/projects"><Text className="__logo-text">PulseSac</Text>
                    </a>
                   
                </div>
                <div className="connect-container">
                
                    {address?
                    <Space size={20}> 
                        <Button
                                className="create-project"
                                type="primary"
                                htmlType="button"
                                shape="round"
                                onClick={()=>navigate('/projects/new')}
                            >
                            Create Project
                        </Button>
                        <Button onClick={disconnect} className="disconnect-wallet" type="dashed"  shape="round" >
                        ({`${shorternAddress(address)}`})
                       <b>{' '}Disconnect</b></Button>
                    </Space>
                    :<Button onClick={connect} className="connect-wallet" shape="round" >Connect Wallet</Button>
                    }
                    <Switch style={{display:'none'}} checked={themeType == 'light'} onChange={onChange} />
                </div>
            </StyledHeader>)
};
