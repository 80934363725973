import { NewProjectComponent } from "../../components";
import { useProject, useUIState } from "../../hooks";
import { notification } from "antd";

import { useContext, useState } from "react";
import { Container } from "./index.styled";
import { useNavigate } from "react-router-dom";
import { WalletConnectorContext } from "../../contexts";
import moment from "moment";

export const NewProjectContainer = () => {
  const KEY = `@@create-new-project`;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const { uiLoaders } = useUIState();
  const loading = uiLoaders[KEY];
  const [totalPhaseDays, setTotalPhaseDays] = useState(0);
  const {address, connect, loading: loadingConnect} = useContext(WalletConnectorContext);
  const { handleCreate } = useProject({
    key: KEY,
  });
  
  const handleComplete = (data: any) =>{
    const completeData = {
      ...formData, 
      ...data,vestingSchedule: data.vestingSchedule.map((vestingSchedule:any)=>({
        ...vestingSchedule, 
        timestamp: vestingSchedule.timestamp.unix()
      })),
    }
    console.log('data: any',completeData, {...data})
    handleCreate(completeData, {
      onFinish: (project: Record<string, any>) => {
        notification.open({
          message: 'Success',
          description:
            'Project Created Successfully',
          onClick: () => {
            navigate(`/projects/${project.id}/new`);
          },
        });
        navigate(`/projects/${project.id}/new`);
        
      },
    });
    

    // router.push('/projects/new/1')
  }
  const handleBackArrow = ()=>{
    navigate(-1)
  }
  const _OnSubmitFormOne =  (data: any)=>{
    setFormData(data)
    console.log('data', data);
  }

  const _OnSubmitFormTwo =  (data: any)=>{
    const {phases, uplineMustBeSacrificer, startDayTime} = data;
    setFormData(oldData=> (
                  {
                    ...oldData,
                    ...data, 
                    uplineMustBeSacrificer: !!uplineMustBeSacrificer, 
                    startDayTime: startDayTime.unix()
                  }
                  ) )

    const daysDiff = moment.duration(startDayTime.startOf('day').diff(moment().startOf('day'))).asDays() 
    + 
    phases.reduce((prev:number, current:any)=>(prev +current.duration), 0);
    setTotalPhaseDays(daysDiff)
    console.log('data', {...data, startDayTime: data.startDayTime.unix()}, totalPhaseDays);
  }
  const handleConnect = ()=>{
    connect?.();
  }
  return (
    <Container>
        <NewProjectComponent 
          handleBackArrow={handleBackArrow} 
          onComplete={handleComplete}
          _OnSubmitFormOne={_OnSubmitFormOne}
          _OnSubmitFormTwo={_OnSubmitFormTwo}
          loading={loading}
          loadingConnect={loadingConnect}
          handleConnect={handleConnect}
          address={address}
          totalPhaseDays={totalPhaseDays}
          />
    </Container>
  );
};
