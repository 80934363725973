import styled from "styled-components";

export const MainContainer = styled.main`
  .__app_main, .__app_secondary{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 552px;
    overflow-x:  hidden;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    padding: 20px ;
    margin-left: auto;
    margin-right: auto;
    

    @media (max-width: 425px) {
      width: 100vw;
    }
    
  }

  .__app_secondary{
    width: 1120px;
    padding: 0px ;
    padding-top: 56px ;
    @media (max-width: 1024px) {
      width: 90vw;
    }
  }
  .error-space{
      justify-content: center;
      padding: 20px;
      width: 100%;
  }
  
`;
