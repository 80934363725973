
import { Typography, Space } from "antd";
import { JoinSection } from "../join";
import { Container} from "./index.styled";

const { Text } = Typography;

interface MainFooterProps {
    text?: string;
}
export const MainFooter = (props: MainFooterProps) => {
    const { } = props;
    
    return (
        <Container>
          <JoinSection />
          <Space className="main-container" align="center">
            <Space className="logo-container" size={8} align="center">
              <img src="/pulsesac_logo.svg" alt="...." />
              <Text>PulseSac</Text>
            </Space>
    
            <Space className="link-container" size={40} align="center">
              <a  rel="noreferrer"  href="https://twitter.com/pulsesac_dapp" target="_blank">
                Twitter
              </a>
              <a  rel="noreferrer" href="https://t.me/PulseSac" target="_blank">
                Telegram
              </a>
              <a  rel="noreferrer" href="https://t.me/PulseSacNews" target="_blank">
                Telegram News
              </a>
              <a  rel="noreferrer" href="https://www.reddit.com/r/pulsesac/" target="_blank">
                Reddit
              </a>
              <a  rel="noreferrer" href="https://medium.com/@pulsesac" target="_blank">
                Medium
              </a>
            </Space>
          </Space>
        </Container>
      );
};


