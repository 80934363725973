import { Middleware } from "redux";
import { RootState } from "../../../state";
import {
  ContractType,
  GET_CONTRACTS,
  setUIError,
  startUILoading,
  stopUILoading,
} from "../../../actions/index";
import Web3 from "web3";

export const getContracts: Middleware<unknown, RootState> =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === GET_CONTRACTS.START) {
      const { provider, contracts = [], key = "GET_CONTRACTS" } = action.meta;
      try {
        dispatch(startUILoading(key));
        const web3 = new Web3(provider);
        const payload: any = {};
        contracts.forEach((contract: ContractType) => {
          payload[contract.title] = new web3.eth.Contract(
            contract.abi,
            contract.address
          );
        });
        dispatch(stopUILoading(key));
        dispatch({ type: GET_CONTRACTS.SUCCESS, payload });
      } catch (e) {
        dispatch(stopUILoading(key));
        dispatch(setUIError(key, e as string));
        dispatch({ type: GET_CONTRACTS.ERROR, e });
      }
    }
  };
