import { EMPTY_ADDRESS, NETWORKS, NETWORKS_TYPE } from "../../../../_shared/constants";
import { moneyFormat, resolveNetworkObject } from "../../../../_shared/utils";
import { Button, Dropdown, Menu, Space, Table, Typography } from "antd";
import { ManageNetworkWrapper } from "./index.styled";
import { ProjectNamespace } from "../../../../_shared/namespaces/project";
import { toEther } from "../../../../web3/libs/utilities";
const {Text} = Typography;

interface ManageNetworkComponentProps{
    handleNetworkSelect : (data?:any)=>void
    handleSelectAvailableNetwork: ((data?: any) => void)
    handleDeploy: ((data?: any) => void)
    handleManageToken: ((data?: any) => void)
    project: ProjectNamespace.Project;
    projectOwners?: string
    networks: string[]
    loadingNetworkSelect: boolean
    loadingDeploy: boolean
    
    currentNetwork?: NETWORKS_TYPE
    switchingNetwork?: NETWORKS_TYPE
    fees?: {
      currentFeeFlat: string,
      currentFeePercentx100: string,
    }
    usdDecimals: number | string;
}
export const ManageNetworkComponent = (props: ManageNetworkComponentProps) => {
    const {handleNetworkSelect, handleSelectAvailableNetwork, handleDeploy,handleManageToken,
      projectOwners, project,
      loadingDeploy, switchingNetwork, networks, currentNetwork, loadingNetworkSelect} = props;
    const resolvedNetworks: NETWORKS_TYPE[] =  resolveNetworkObject(networks)
    const {networkTotals} = project;

    const AddNetworkMenu = (
        <Menu
        
          items={
            NETWORKS.filter((nw)=> {
             return process.env.REACT_APP_ENVIRONMENT !== 'production' || nw.chainId !== '97';
            }).map((network)=>(
              {
                key: network.chainId,
                label: network.label,
                onClick: ()=>{
                  handleNetworkSelect(network)
                }
              }
            ))}
        />
      );

    const dataSource = resolvedNetworks.map((network, index)=>{
      return {
        key: index,
        action: '10 Downing Street',
        total_raised: `${networkTotals?'~':''}$${moneyFormat(toEther( networkTotals?.[network.chainId]?.contributed??'', 2))}`,
        network,
        

      }
    })
    
    const columns = [
      {
        title: 'Network',
        dataIndex: 'network',
        key: 'network',
        render: ( network:any) => {
          return <span>{network.label}</span>
        }
      },
      {
        title: 'Total Amount Raised',
        dataIndex: 'total_raised',
        key: 'total_raised',
      },
      {
        title: 'Action',
        key: 'action',
        dataIndex: 'network',
        render: ( network:NETWORKS_TYPE) => {
          const renderActionButton = projectOwners && projectOwners!== EMPTY_ADDRESS?
            <Button onClick={handleManageToken} shape="round" className="add-new-token-btn">
              Manage Currencies
            </Button>
          :
          currentNetwork && <Button loading={loadingDeploy} onClick={handleDeploy} className="deploy-btn" style={{margin: '0'}} type="primary" shape="round">
                Deploy to {currentNetwork.label}
            </Button>
          


          return <Space size="middle">
            {currentNetwork?.chainId !== network.chainId ?<Button 
                type="primary" 
                shape="round"
                loading={switchingNetwork?.chainId === network.chainId}
                onClick={ ()=>{
                  handleSelectAvailableNetwork(network)
                }}
                 >Switch to {network.label}
            </Button>:renderActionButton}
          </Space>
       },
      },
    ];

    return (
          <ManageNetworkWrapper>
              <div className="hint" >
            <Text>
              Manage networks on which you want to receive donations as well as the currencies (tokens) you want to accept on each network.
            </Text>
            </div>
            <Space className="top-bar" align="center" style={{justifyContent:'flex-end'}}>
                <Dropdown disabled={loadingNetworkSelect} overlay={AddNetworkMenu} placement="bottom" >
                  <Button shape="round" loading={loadingNetworkSelect} >
                      Add New Network
                  </Button>
                </Dropdown>
                
            </Space>
            <Table dataSource={dataSource} columns={columns} />

          </ManageNetworkWrapper>
    );
};