import { MyProjects } from "../../components";
import { WalletConnectorContext } from "../../contexts";
import { useProject, useUIState, useUser, } from "../../hooks";
import {  useContext, useEffect, useState } from "react";
import { Container } from "./index.styled";
import { useNavigate } from "react-router-dom";

export const ViewMyProjectsContainer = () => {

  const navigate = useNavigate();
  const MY_KEY = `@@view-my-project`;
  const ALL_KEY = `@@view-all-project`;
  const { allProjects:myProjects, handleGetAll: handleGetAllMy} = useProject({
    key: MY_KEY,
  });
  const { pastProjects, handleGetPastProjects} = useUser({
    key: MY_KEY,
  });
  const {address, connect, loading} = useContext(WalletConnectorContext);
  const { uiLoaders, pagination } = useUIState();
  const myProjectLoading = uiLoaders[MY_KEY];
  const myProjectPagination = pagination[MY_KEY];
  const allProjectLoading = uiLoaders[ALL_KEY];
  const [filterText, setFilterText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if(!address) return
    handleGetAllMy({
      params:{
        user: address,
        page: currentPage,
        per_page: pageSize
      }
    })
    handleGetPastProjects(address, 'getDonorProjects')
  }, [address, currentPage, pageSize]);

  


  const handleOnNewProject = ()=>{
    navigate(`/projects/new`);
  }
  const handleConnect = ()=>{
    connect?.();
  }
  const handleSearchChange = (event:any)=>{
    setFilterText(event.target.value)
  }
  const filteredMyProject =  (myProjects??[]).filter(project=>{
    if(!filterText) return true;
    return project?.title?.toLowerCase().includes(filterText.toLowerCase())
  })
  const handleMyProjectPagination = (page: number, pageSize:number)=>{
    setCurrentPage(page);
    setPageSize(pageSize);
    console.log(`handleMyProjectPagination`, page, pageSize)
  }
  
  // const filteredPastProjects =  (pastProjects??[]).filter(project=>{
  //   if(!filterText) return true;
  //   return project?.title?.toLowerCase().includes(filterText.toLowerCase())
  // })
  return (
    <Container>
        <MyProjects 
          address={address}
          loadingConnect={loading}
          handleOnNewProject={handleOnNewProject} 
          myProjects={filteredMyProject} 
          myProjectLoading={myProjectLoading} 
          myProjectPagination={myProjectPagination}
          allProjects={pastProjects} 
          handleConnect={handleConnect}
          handleSearchChange={handleSearchChange}
          handleMyProjectPagination={handleMyProjectPagination}
          allProjectLoading={allProjectLoading}/>
    </Container>
  );
};
