import styled from "styled-components";

export const VolumeBonusWrapper = styled.div`
    display: flex!important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    gap:0px;
    &, .top-bar, .list-container, .btn-container{
        width: 100%!important;
    }
    .top-bar{
        margin-top: 40px;
        flex-wrap: wrap;
        .ant-space-item:nth-child(1){
            margin-left: auto;
        }
        /* justify-content: space-between; */
    }
    .ant-btn, .ant-typography{
        font-weight: 400!important;
        font-size: 16px!important;
        line-height: 24px!important;
    }
    
    
    .new-group-btn{
        background-color: var(--success-color)!important;
        border-color: var(--success-color)!important;
    }

    .muted{
        color: var(--disabled-color)!important;
    }

    .list-container{
        margin-top: 52px;
        margin-bottom: 90px;
        gap: 20px!important;
    }
    .delete-btn{
        color: #F87171!important;
    }

    .btn-container{

        justify-content: flex-end ;
    }
    /* var(--success-color) */
`;
