import { Space } from "antd";
import styled from "styled-components";

export const FormWrapper = styled(Space)`
    width: 100%;
    .ant-form, .ant-form-item-control, .ant-input-textarea, .ant-picker, .ant-input-number{
        width: 100%!important; 
    } 
    .ant-input-affix-wrapper, .ant-picker{
        padding-top: 0px!important;
        padding-bottom: 0px!important;
    }
    .ant-select-selector{
        align-items:center ;
    }
    
    .ant-form-item-label{
        label{
            font-weight: 400;
            font-size: 14px!important;
            line-height: 20px;
        }
    }
    .ant-input-suffix{
        color: var(--disabled-color);
    }
    .ant-form-item-control{
        input, select,.ant-select-selector{
            height: 56px!important;
            ::placeholder{
                color: var(--disabled-color);
            }
        }
        textarea{
            background-color: transparent;
        }
    }

    .ant-form-item-control-input-content{
        display: flex;
        align-items: center;
        gap: 16px;
        flex-wrap: nowrap;
        justify-content: start;
        input{
            flex-grow: 1;
        }
    }

    .ant-input-textarea-show-count{
        &::after{
            color: var(--disabled-color)!important;
            margin-top: -25px;
            margin-right: 10px;
            z-index:2 ;
        }
    }
    .ant-divider{
        border-color: var(--border)!important;
    }
    .ant-btn.next-btn, .ant-btn.finish-btn{
        background: #16A34A!important;
        border-color: #16A34A!important;
    }
    .ant-btn.next-btn{
        margin-left: auto;
    }
    .ant-btn.prev-btn{
        margin-left: auto;
    }
    .ant-form-text{
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
    }

    .double-child-row{
        & > .ant-col:first-child{
            padding-right: 8px;
        }
        & > .ant-col:last-child{
            padding-left: 8px;
        }
        &, .ant-form-item, .ant-input-number{
            width: 100%;
        }
        .ant-space-item{
            flex-grow: 1;
        }

        @media (max-width: 425px) {
            & > .ant-col:first-child{
                padding-right: 0px;
            }
            & > .ant-col:last-child{
                padding-left: 0px;
            }
        }
        
    }

    .label-with-tip{
        label, .ant-space{
            width: 100%;

        }
        .ant-space{
            justify-content: space-between;
            .ant-space-item{
                width: fit-content!important;
            }
        }
    }
    .dynamic-delete-button{
        width: fit-content;
    }
    .social-media-items{
        width: 100%;
        @media (max-width: 425px) {
            flex-wrap: wrap;
            .ant-row{
                display: block!important;
            }
        }
        .ant-row{
            width: 100%;
        }
        .ant-space-item:nth-child(3){
            width: fit-content;
        }
    }
`;
