import { Button, Form, InputNumber } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { useEffect } from "react";
import { ModalWrapper } from "../index.styled";
import { FormWrapper } from "./index.styled";

interface NewAdminBonusModalProps{
    visible: boolean
    loading:boolean
    onFinish?: ((values: any) => void) | undefined
    onClose?: () => void
    selectedAdminBonus?: {contributionAmount: string; points:string;}
}
export const NewAdminBonusModal = (props: NewAdminBonusModalProps) => {
    const {visible, onFinish, onClose, loading, selectedAdminBonus} = props;
    const [form] = useForm()
    useEffect(() => {
        form.resetFields();
    });
    return (
          <ModalWrapper  
                title={`${selectedAdminBonus?'Edit':'Add'} Admin Bonus`} 
                footer={null}
                visible={visible}
                onCancel={onClose}
                >
            
            <FormWrapper
                name="new_volume_bonus"
                layout="vertical"
                initialValues={selectedAdminBonus}
                onFinish={onFinish}
                form={form}
                autoComplete="off"
                >
                <Form.Item
                    label="Points"
                    name="points"
                    requiredMark={'optional'}
                    rules={[{ required: true, message: 'Please input point!' }]}
                >
                    <InputNumber placeholder="Enter Points" />
                </Form.Item>
                <Form.Item
                    label="Address"
                    name="addresses"
                    requiredMark={'optional'}
                    rules={[{ required: true, message: 'Please input amount!' }]}
                    extra={'Seperate addresses with comma'}
                >
                    <TextArea rows={4} />
                </Form.Item>
                
                <Form.Item>
                    <Button loading={loading} shape="round" className="submit-btn" type="primary" htmlType="submit">
                    {`${selectedAdminBonus?'Update':'Save'} Bonus`} 
                    </Button>
                </Form.Item>
                </FormWrapper>
          </ModalWrapper>
    );
};