
import { Container} from "./index.styled";
import { AvailableSection } from "./lib/available";
import { FaqsSection } from "./lib/faqs";
import { GraphSection } from "./lib/graph";

interface SectionThreeProps {
    text?: string;
    faqTabContent:any
}
export const SectionThree = (props: SectionThreeProps) => {
    const { faqTabContent } = props;
    return (<Container>
            
            <GraphSection/>
            <AvailableSection/>
            <FaqsSection faqTabContent={faqTabContent}/>
        </Container>)
};


