import { callMethod, resetMethod, sendMethod } from '../../redux/actions';
import {getContracts}  from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/state';
import {  ContractMetaData, ContractTypes } from '../../_shared/constants';
import { useCallback, useEffect, useState } from 'react';
import { ProjectNamespace } from '../../_shared/namespaces/project';
import { v9, sacToken } from '../../_shared/ABIcode';
import { AcceptedToken, SacrificeWithBonusType } from '../../_shared/utils/type';


interface UseSacProxyProps {
  address?: string | undefined;
  key?: string;
  provider?: any;
}

interface SacrificeOptions {

    onFinish? : (data?: any) => void,
    onError? : (data?: any) => void,
    value? : string,
  
}
export type SacProxy = {
  getSacrificeContractAddress: (projectId: string, version: number) => void;
  getProjectOwners: (projectId: string) => void;
  getSacrificeFee: () => void;
  getDollarValue: (address: string, amount: string, onFinish?: (data:any)=>void) => void;
  getSacrificePhaseDay: () => void;
  getPointRate: () => void;
  getPointRateForDay: () => void;
  getAcceptedTokens: () => void;
  getProjectInfo: () => any;
  getDollarAndPointsAmount: (token: string, amount: string, sacAddress: string, onFinish?: (data:any)=>void) => void
  deploySacrifice: (project?: ProjectNamespace.Project, options?:SacrificeOptions)=>void
  setAcceptedTokens: (address: string[], flag: boolean, options?:SacrificeOptions)=>void
  getCalculatePointToken: (amountInWeigh: string, tokenAddress:string, onFinish?: (data:any)=>void)=>void
  getSacrificeToken: (amount: string,referalAddress: string, tokenAddress:string)=>void
  getWithdraw: (tokenAddress:string, options: SacrificeOptions)=>void
  
  initTokenContract: (tokenAddress:string)=>void
  getSacrificeWithBonus: (data: SacrificeWithBonusType, options?:SacrificeOptions)=>void
  getAllowance: (options: SacrificeOptions)=>void
  getBalanceOf: (options: SacrificeOptions)=>void
  sendApprove: (amount:string ,options: SacrificeOptions)=>void
  resetStoreData:(options?:SacrificeOptions)=>void 
  getUserInfo:(options?:SacrificeOptions)=>void 
  addBonus:(addresses: string[], points: string,options?:SacrificeOptions)=>void 
  cancelAdminBonus:(address: string, bonusId: string,options?:SacrificeOptions)=>void 
  getUsdDecimals: () => void
  loadingDeploy: boolean,
  loadingIfDeployed: boolean,
  loadingAddress: boolean,
  loadingAddToken: boolean,
  loadindDollarValue: boolean,
  loadingCalculatePointToken: boolean,
  address?: string,
  projectOwners?: string,
  acceptedTokens?: AcceptedToken[]
  totalSacUSD?: string,
  dollarValue?: string,
  sacrificePhaseDay?: string,
  pointRate?: string,
  calculatePointToken?: string,
  fees?: {
    currentFeeFlat: string,
    currentFeePercentx100: string,
  },
  sacrificeToken?:any
  sacrificeWithBonus?:any
  usdDecimals: string
  
};

export const useSacProxy = (props: UseSacProxyProps): SacProxy => {
  const dispatch = useDispatch();
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [loadingDeploy, setLoadingDeploy] = useState(false);
  const [loadingAddToken, setLoadingAddToken] = useState(false);
  const [loadingIfDeployed, setLoadingIfDeployed] = useState(false);
  const [loadindDollarValue, setLoadindDollarValue] = useState(false);
  const [loadingCalculatePointToken, setLoadingCalculatePointToken] = useState(false);
  const { address, provider } = props;
  const { contract: { data } = {} } = useSelector(({ contract }: RootState) => ({
    contract,
  }));

  const fees = data?.SacProxy?.fees??undefined;
  const sacAddress = data?.SacProxy?.getSacrificeContractAddress??undefined;
  const projectOwners = data?.SacProxy?.projectOwners??undefined;
  const dollarValue = data?.SacProxy?.dollarValue??undefined;
  const acceptedTokens = data?.SacProxy?.getAcceptedTokens??undefined;
  const sacrificeWithBonus = data?.SacProxy?.sacrificeWithBonus??undefined;
  const usdDecimals = data?.SacProxy?.usdDecimals??undefined;

  const totalSacUSD = data?.SacContract?.projectInfo?.totalContributed??undefined;
  const sacrificePhaseDay = data?.SacContract?.getSacrificePhaseDay??undefined;
  const pointRate = data?.SacContract?.getPointRate??undefined;
  const calculatePointToken = data?.SacContract?.calculatePointToken??undefined;
  const sacrificeToken = data?.SacContract?.sacrificeToken??undefined;
 
  
  
  
  useEffect(() => {

    if(sacAddress){
      dispatch(getContracts(provider, [ContractMetaData[0], {
          title: 'SacContract',
          abi: v9,
          address: sacAddress,
        }
      ]));  
    }
  }, [sacAddress]);


  const getSacrificeContractAddress =  async (projectId: string, version: number) => {
    setLoadingAddress(true)
    dispatch(
      callMethod(
          ContractTypes.sacProxy,
          'getSacrificeContractAddress',
          [projectId, `${version}`],
          {
              onFinish: () => {
                  setLoadingAddress(false)
              },
              onError: () => {
                setLoadingAddress(false)
            },
          }
      )
  );
      

    
  };

  const getProjectOwners =  async (projectId: string) => {
    setLoadingIfDeployed(true)
    dispatch(
      callMethod(
          ContractTypes.sacProxy,
          'projectOwners',
          [projectId],
          {
              onFinish: () => {
                  setLoadingIfDeployed(false)
              },
              onError: () => {
                setLoadingIfDeployed(false)
            },
          }
      )
  );
  };

  const getSacrificeFee =  async () => {
    dispatch(
      callMethod(
          ContractTypes.sacProxy,
          'fees',
          []
      )
  );
  };
  

  const getUsdDecimals =  async () => {
    console.log('GETTTTTTTTTUSD');
    dispatch(
      callMethod(
          ContractTypes.sacProxy,
          'usdDecimals',
          []
      )
  );
  };

  const getDollarValue =  async (address: string, amount: string, onFinish?: (data:any)=>void) => {
    setLoadindDollarValue(true)
    dispatch(
      callMethod(
          ContractTypes.sacProxy,
          'dollarValue',
          [address, amount],
          {
            onFinish: (d:any) => {
              setLoadindDollarValue(false);
              onFinish?.(d)
            },
            onError: (e: any) => {
              setLoadindDollarValue(false)
            },
          }
      )
  );    
  };

  const getDollarAndPointsAmount =  async (token: string, amount: string, sacAddress: string,  onFinish?: (data:any)=>void) => {
    setLoadindDollarValue(true)
    dispatch(
      callMethod(
          ContractTypes.sacProxy,
          'getDollarPointsAmounts',
          [token, amount, sacAddress],
          {
            onFinish: (d:any) => {
              setLoadindDollarValue(false);
              onFinish?.(d)
            },
            onError: (e: any) => {
              setLoadindDollarValue(false)
            },
          }
      )
  );    
  };


  const getAcceptedTokens =  useCallback(async () => {
    
    dispatch(
        callMethod(
            ContractTypes.sacProxy,
            'getAcceptedTokens',
            [sacAddress]
        )
    );
  }, [sacAddress]);
  

  const setAcceptedTokens = (addressParam: string[], flag: boolean = true, options?:SacrificeOptions) => {
   
    setLoadingAddToken(true)
    dispatch(
      sendMethod(
        ContractTypes.sacContract,
        'setAcceptedTokens',
        [addressParam, flag],
        [
          {
            from: address
          },
        ],
        {
          onFinish: () => {
            setLoadingAddToken(false)
            options?.onFinish?.()
          },
          onError: (e: any) => {
            console.log(`onError`, e)
            setLoadingAddToken(false)
            options?.onError?.()
          },
        }
      )
    );
      
  };

  const deploySacrifice = (project?: ProjectNamespace.Project, options?:SacrificeOptions) => {
    const { referralBonusPercent:referalPercent, startDayTime:startDate, 
          minContributionAmount:minimumSacAmount, 
          uplineMustBeSacrificer:memberOnlyUpline,
          user: projectOwner,
          id: projectId,
          phases,
          vestingSchedule: claimPlan,
          contractVersion: version,
          signature
         } = project || {};
    setLoadingDeploy(true)
    const args = [{
      referalPercent : Number(referalPercent) * 100 ,
      refereePercent : Number(referalPercent) * 100 ,
      startDate,
      minimumSacAmount,
      memberOnlyUpline: !!memberOnlyUpline,
      projectOwner,
      projectId: `${projectId}`,
      phases,
      version: `${version}`,
      claimPlan: claimPlan?.map(data=>{
        return {...data, percentage: Number(data.percentage) * 100 }
      })??[],
      acceptedTokens: []
    }, signature]
    console.log('args', args)
    dispatch(
      sendMethod(
        ContractTypes.sacProxy,
        'deploySacrifice',
        [...args],
        [
          {
            from: address,
            value: fees?.currentFeeFlat??"0"
          },
        ],
        {
          onFinish: () => {
            setLoadingDeploy(false)
            options?.onFinish?.()
          },
          onError: (e: any) => {
            console.log(`onError`, e)
            setLoadingDeploy(false)
            options?.onError?.()
          },
        }
      )
    );
      
  };

  const getProjectInfo =  async () => {
    dispatch(
        callMethod(
            ContractTypes.sacContract,
            'sacProjectInfo',
            []
            
        )
    );
  };


  // const getTotalSacUSD =  async () => {
  //   dispatch(
  //       callMethod(
  //           ContractTypes.sacContract,
  //           'totalSacUSD',
  //           []
            
  //       )
  //   );
  // };

  const getSacrificePhaseDay =  async () => {
    dispatch(
        callMethod(
            ContractTypes.sacContract,
            'getSacrificePhaseDay',
            [],
        )
    );
  };

  const getPointRate =  async () => {
    dispatch(
        callMethod(
            ContractTypes.sacContract,
            'getPointRate',
            [],
        )
    );
  };

  const getPointRateForDay =  useCallback(async ()=>{
    dispatch(
      callMethod(
          ContractTypes.sacContract,
          'getPointRateForDay',
          [sacrificePhaseDay],
      )
  );
  }, [sacrificePhaseDay]);


  const getCalculatePointToken =  useCallback(async (amountInWeigh: string, tokenAddress:string, onFinish?: (data:any)=>void)=>{
    console.log('calcPoints', amountInWeigh);
    setLoadingCalculatePointToken(true)
    dispatch(
      callMethod(
          ContractTypes.sacContract,
          'calculatePointToken',
          [amountInWeigh, sacrificePhaseDay, tokenAddress],
          {
            onFinish: (d:any) => {
              setLoadingCalculatePointToken(false)
              onFinish?.(d)
            },
            onError: (e: any) => {
              setLoadingCalculatePointToken(false)
            },
          }
      )
  );
  }, [sacrificePhaseDay]);

  const getUserInfo =  useCallback((options:SacrificeOptions)=>{
    
    dispatch(
      callMethod(
          ContractTypes.sacContract,
          'userInfo',
          [address],
          {
            onFinish: (data) => {
              options?.onFinish?.(data)
            },
            onError: (e: any) => {
              console.log(`onError`, e)
              options?.onError?.()
            },
          }
      )
  );
  }, [address, sacAddress]);


  const getWithdraw =  useCallback((tokenAddress:string, options:SacrificeOptions)=>{
    
    dispatch(
      sendMethod(
        ContractTypes.sacContract,
        'withdraw',
        [tokenAddress],
        [
          {
            from: address
          },
        ],
        {
          onFinish: () => {
            options?.onFinish?.()
          },
          onError: (e: any) => {
            console.log(`onError`, e)
            options?.onError?.()
          },
        }
      )
  );
  }, [address, sacAddress]);


  const getSacrificeToken =  (amount: string,referalAddress: string, tokenAddress:string)=>{
    dispatch(
      callMethod(
          ContractTypes.sacContract,
          'sacrificeToken',
          [amount, referalAddress, tokenAddress],
        )
    );
  };
  
  const addBonus =  useCallback(( addresses: string[], points: string,options:SacrificeOptions)=>{
    dispatch(
      sendMethod(
        ContractTypes.sacContract,
        'addAdminBonus',
        [addresses, points],
        [
          {
            from: address
          },
        ],
        {
          onFinish: () => {
            options?.onFinish?.()
          },
          onError: (e: any) => {
            console.log(`onError`, e)
            options?.onError?.()
          },
        }
      )
    );
    
  }, [address, sacAddress]);


  const cancelAdminBonus =  useCallback(( _address: string, bonusId: string, options:SacrificeOptions)=>{
    dispatch(
      sendMethod(
        ContractTypes.sacContract,
        'cancelAdminBonus',
        [_address, bonusId],
        [
          {
            from: address
          },
        ],
        {
          onFinish: () => {
            options?.onFinish?.()
          },
          onError: (e: any) => {
            console.log(`onError`, e)
            options?.onError?.()
          },
        }
      )
    );
    
  }, [address, sacAddress]);

  const initTokenContract =  ( tokenAddress:string)=>{
    dispatch(getContracts(provider, [ContractMetaData[0],
      {
        title: 'SacContract',
        abi: v9,
        address: sacAddress,
      },
       {
        title: 'SacToken',
        abi: sacToken,
        address: tokenAddress,
      }
  ])); 
  };




  const getSacrificeWithBonus =  (data: SacrificeWithBonusType, options?:SacrificeOptions)=>{
    const sendArgs: any = {
      from: address
    };
    if (options?.value) {
      sendArgs.value = options.value
    }
    dispatch(
      sendMethod(
        ContractTypes.sacProxy,
        'sacrificeWithBonus',
        [sacAddress, data._amount, data._referalAddress, data._token, data._bonus, data._signature],
          [
            sendArgs,
          ],
          {
            onFinish: () => {
              options?.onFinish?.()
            },
            onError: (e: any) => {
              console.log(`onError`, e)
              options?.onError?.()
            },
          }
        )
    );
  };
  
  const getAllowance =  useCallback(( options:SacrificeOptions)=>{
    dispatch(
      callMethod(
          ContractTypes.sacToken,
          'allowance',
          [address, sacAddress],
          {
            onFinish: (data:any) => {
              options?.onFinish?.(data)
            },
            onError: (e: any) => {
              console.log(`onError`, e)
              options?.onError?.()
            },
          }
        )
    );
  }, [address, sacAddress]);

  const getBalanceOf =  useCallback(( options:SacrificeOptions)=>{
    dispatch(
      callMethod(
          ContractTypes.sacToken,
          'balanceOf',
          [address],
          {
            onFinish: (data:any) => {
              options?.onFinish?.(data)
            },
            onError: (e: any) => {
              console.log(`onError`, e)
              options?.onError?.()
            },
          }
        )
    );
  }, [address]);


  const sendApprove =  useCallback((amount: string, options:SacrificeOptions)=>{
    dispatch(
      sendMethod(
        ContractTypes.sacToken,
        'approve',
        [sacAddress, amount],
        [
          {
            from: address
          },
        ],
        {
          onFinish: () => {
            options?.onFinish?.()
          },
          onError: (e: any) => {
            console.log(`onError`, e)
            options?.onError?.()
          },
        }
      )
    );
  }, [address, sacAddress]);


  const resetStoreData =  async (options:SacrificeOptions) => {
    setLoadingIfDeployed(true)
    dispatch(
      resetMethod(
          ContractTypes.sacProxy,
          'projectOwners',
          [],
          {
            onFinish: () => {
              setLoadingAddToken(false)
              options?.onFinish?.()
            },
          }
      )
  );
      

    
  };
  

  return {
    getSacrificeContractAddress,
    deploySacrifice,
    getSacrificeFee,
    getDollarValue,
    getProjectOwners,
    getAcceptedTokens,
    setAcceptedTokens,
    getProjectInfo,
    getSacrificePhaseDay,
    getPointRate,
    getPointRateForDay,
    getCalculatePointToken,
    getSacrificeToken,
    getSacrificeWithBonus,
    initTokenContract,
    getAllowance,
    getBalanceOf,
    sendApprove,
    resetStoreData,
    addBonus,
    cancelAdminBonus,
    getUserInfo,
    getWithdraw,
    getDollarAndPointsAmount,
    getUsdDecimals,
    loadingDeploy,
    loadingIfDeployed,
    loadingAddress,
    loadingAddToken,
    loadindDollarValue,
    loadingCalculatePointToken,
    dollarValue,
    pointRate,
    fees,
    address:sacAddress,
    projectOwners,
    acceptedTokens,
    totalSacUSD,
    sacrificePhaseDay,
    calculatePointToken,
    sacrificeToken,
    sacrificeWithBonus,
    usdDecimals
    
  } as SacProxy;
};
