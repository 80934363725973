import {  Button,  Form, Input, List, Pagination, Space } from "antd";


import { ProjectNamespace } from "../../../_shared/namespaces/project";
import { ProjectItem } from "../../utils";


interface MyProjectTabProps {
  myProjects: ProjectNamespace.Project[]
  myProjectLoading: boolean
  pagination: any
  handleOnNewProject: (data?:any)=>void
  handleSearchChange: (data:any)=>void
  onPaginationChange: (page: number, pageSize:number)=>void
}


export const MyProjectTab = (props:MyProjectTabProps) => {
  const {myProjects, myProjectLoading,pagination, handleOnNewProject, handleSearchChange, onPaginationChange } = props;  

  return (
    <>
        <Form  name="horizontal_form" layout="inline" >
          <Form.Item
              className="search-form-item"
              name="username"
          >
              <Input onChange={handleSearchChange} placeholder="Search Project Name" />
          </Form.Item>
        

        <Form.Item className="add-form-item">
            <Button
                type="primary"
                htmlType="button"
                shape="round"
                onClick={handleOnNewProject}
            >
                Create Project
            </Button>
        </Form.Item>
        </Form>
        <List
          loading={myProjectLoading}
          itemLayout="horizontal"
          // loadMore={loadMore}
        

          dataSource={myProjects}
          renderItem={project => <ProjectItem loading={myProjectLoading} project={project}/>}/>
        <Space align="center" className="pagination-space">
          <Pagination 
            disabled={myProjectLoading} 
            current={pagination?.current??1} 
            pageSize={pagination?.per_page??10} 
            total={pagination?.total_count??50} 
            onChange={onPaginationChange} />
        </Space>
    </>
  );
};