import { ReactNode, Suspense } from 'react';
import './styles/globals.css'
import './styles/sacrifice.min.css'
import './styles/override.css'
import LandingPage from './pages/landing';
import MyProjectsPage from './pages/projects';
import NewProjectPage from './pages/projects/new';
import NewProjectSuccessPage from './pages/projects/[id]/new';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Page404 from './pages/404';
import { MainLayoutContainer } from './container';
import SacPage from './pages/sac/[id]';
import ViewSacrificePage from './pages/projects/[id]/view';
import EditSacrificePage from './pages/projects/[id]/edit';

const Loading = () => null;

const App = () => {
  const getLayout = ((Component: ReactNode) => {
    const renderClassName = (Component as any)?.renderClassName;
    return <MainLayoutContainer renderClassName={renderClassName}>
      {Component}
      </MainLayoutContainer>
});
  return (
    <div data-id={'entry-point'}>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <Routes>
            {/* <Route path="/" element={<LandingPage />}/> */}
            {['/', '/projects'].map((r)=>(<Route path={r}>
              <Route index element={getLayout(<MyProjectsPage />)} />
              <Route path="new" element={getLayout(<NewProjectPage />)} />
              <Route path=":id">
                  <Route path="new" element={getLayout(<NewProjectSuccessPage />)} />
                  <Route path="view" element={getLayout(<ViewSacrificePage />)} />
                  <Route path="edit" element={getLayout(<EditSacrificePage />)} />
              </Route>
            </Route>))
            }
            <Route path="sac">
              <Route path=":id" element={getLayout(<SacPage />)} />
            </Route>
            
            <Route path="*" element={getLayout(<Page404 />)} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  );
};

export default App;