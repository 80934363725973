import {Middleware} from 'redux';
import {RootState} from '../../../state';
import {SEND_METHOD, setUIError, startUILoading, stopUILoading} from '../../../actions';
import {notification} from 'antd';

export const sendMethod: Middleware<unknown, RootState> =
  ({dispatch, getState}) =>
    (next) =>
      async (action) => {
        next(action);
        if (action.type === SEND_METHOD.START) {
          const {functionName, contractName, functionArgs, sendArgs, options} = action.meta;
          const {
            key = functionName || 'SEND_METHOD',
            onFinish,
            onError,
            noSuccessMessage = false,
            noErrorMessage = false,
            successMessage,
            errorMessage
          } = options;
          try {
            dispatch(startUILoading(key));
            const {
              contract: {contracts}
            } = getState();
            const currentContract = contracts[contractName];
            const response = await currentContract.methods[functionName](...functionArgs).send(
              ...sendArgs
            );
            const data: any = {
              [functionName]: response
            };
            dispatch({type: SEND_METHOD.SUCCESS, payload: {contractName, data}});
            onFinish?.();
            dispatch(stopUILoading(key));
            if (!noSuccessMessage) notification.success( {message: successMessage || 'Transaction successful!',
              duration: 8, style: {
                backgroundColor: 'rgba(69,75,27,0.7)',
                marginTop: 0,
              }});
          } catch (e: any) {
            dispatch(stopUILoading(key));
            dispatch(setUIError(key, e));
            dispatch({type: SEND_METHOD.ERROR, e});
            onError?.(e);
            console.log(e);
            if (!noErrorMessage) {
              notification.error({
                message:  errorMessage || e?.message || 'Transaction failed please try again!',
                duration: 7, style: {
                  marginTop: 0,
                  backgroundColor: 'rgba(139,0,0,0.73)'
                }
              });
            }
          }
        }
      };
