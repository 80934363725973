import styled from "styled-components";

export const ManageTokenLoadedWrapper = styled.div`
    display: flex!important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    gap:0px;
    &, .top-bar, .list-container{
        width: 100%!important;
    }
    .top-bar{
        margin-top: 40px;
        flex-wrap: wrap;
        .ant-space-item:nth-child(2){
            flex-grow: 1;
        }
        /* justify-content: space-between; */
    }
    .ant-btn{
        font-weight: 400!important;
        font-size: 16px!important;
        line-height: 24px!important;
    }
    
    .eth-btn{
        background-color: #3C3CC7!important;
    }
    .add-new-token-btn{
        background-color: var(--success-color)!important;
        border-color: var(--success-color)!important;
    }
    .list-container{
        margin-top: 52px;
        margin-bottom: 90px;
        gap: 20px!important;
    }
    .row-list-item{
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--border) ;
    }
    .title-container{
        .name{
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: var(--disabled-color)!important;
        }
        .price{
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
        }
    }
    .mid-text{
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        
        .key{
            color: var(--disabled-color)!important;
        }
    }
    
    /* var(--success-color) */
`;
