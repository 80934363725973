import { DeleteFilled } from "@ant-design/icons";
import { Button, Col, Row, Space, Typography } from "antd";
import { resolveCurrentNetworkObject, shorternAddress } from "../../../../_shared";
import { AdminNamespace } from "../../../../_shared/namespaces/admin";
import { AdminBonusWrapper } from "./index.styled";
const {Text} = Typography;

interface AdminBonusComponentProps{
  adminBonuses: AdminNamespace.AdminBonus[]
  selectedAdminBonus?: AdminNamespace.AdminBonus;
  loading: boolean;
  handletoggleAdminBonusModal : (data?:any)=>void
  handleManageAdminBonus: ((data: any, type:string) => void)

  
}
export const AdminBonusComponent = (props: AdminBonusComponentProps) => {
    const {adminBonuses,loading,  selectedAdminBonus,
      handletoggleAdminBonusModal, handleManageAdminBonus} = props;    
    const renderListData = adminBonuses.map((data, key)=>(
      <>
      <Row key={key}  className="row-list-item">
        <Col xs={24} md={6}>
          <Text className="">{shorternAddress(data.address)}</Text>
        </Col>
        <Col xs={24} md={6}>
          <Text className="">{data.points}</Text>
        </Col>
        <Col xs={24} md={6}>
          <Text className="">{resolveCurrentNetworkObject(`${data.chainId}`)?.label}</Text>
        </Col>
        <Col xs={24} md={6}>
          <Space className="btn-container">
            {/* <Button onClick={()=>{
                handleManageAdminBonus({...data, index:key}, 'set-selected')
                handletoggleAdminBonusModal()
                }} 
                shape="round" className="withdraw-btn">
              Edit
            </Button> */}
            <Button shape="round"
              loading={loading && selectedAdminBonus?.id === data.id}
              onClick={()=>{
                handleManageAdminBonus(data, 'set-selected')
                handleManageAdminBonus(data, 'remove-selected')
                }} 
             type="text" icon={<DeleteFilled />} className="delete-btn"/>
          </Space>
        </Col>
      </Row>
      </>
    ))
    return (
          <AdminBonusWrapper>
              <div className="hint" >
            <Text>
              Issue points directly to one or more addresses
            </Text>
            </div>
            <Space className="top-bar" align="center">
                
                <Button loading={loading && !selectedAdminBonus} onClick={()=>{
                        handleManageAdminBonus(null, 'set-selected')
                        handletoggleAdminBonusModal()
                        }} 
                  shape="round" className="new-group-btn">
                  New Admin Bonus
                </Button>
            </Space>
            <Space className="list-container" direction="vertical">
              <Row>
                <Col xs={24} md={6}>
                  <Text className="muted">Address</Text>
                </Col>
                <Col xs={24} md={6}>
                  <Text className="muted">Points</Text>
                </Col>
                <Col xs={24} md={6}>
                  <Text className="muted">Network</Text>
                </Col>
              </Row>
              {renderListData}
            </Space>
          </AdminBonusWrapper>
    );
  };