import styled from "styled-components";

export const Container = styled.div`
    background-color: var(--landing-background-inverse);
    display: flex!important;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 120px !important;
    padding-right: 120px !important;
    gap: 24px;

    .app-header-row{
        width: 100%;
        margin-top: 120px;
        .ant-typography{
            text-align: center;
        }
    }
    

    h2.ant-typography{
        font-weight: 600!important;
        font-size: 40px!important;
        line-height: 40px!important;
        color: var(--text-color-inverse)!important;
    }

    .m-point-text{
        font-weight: 300;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        .ant-typography{
            color: var(--landing-background-3);
        }
    }
    .linear-bg{
        background: linear-gradient(102.33deg, #11998E -6.44%, var(--process-tail-color) 116.66%)!important;
        border-color: transparent!important;
    }
    .ant-btn{
        font-weight: 400!important;
        font-size: 16px!important;
        line-height: 26px!important;
    }
    .ant-tabs-tab-btn{
        display: flex!important;
        flex-direction: row!important;
        align-items: center!important;
        justify-content: center;
        padding: 4px 32px!important;
        gap: 8px!important;
        width: 221px!important;
        font-weight: 400!important;
        font-size: 16px!important;
        line-height: 26px!important;
    }

    .ant-tabs-nav{
        .ant-tabs-nav-wrap{
            justify-content: start!important;
        }
        .ant-tabs-nav-list{
            background-color: var(--disabled-bg);
            
        }
        
        ::before{
            border: 0!important;
        }
    }

    .features-panel{
        margin-top: 40px!important;
        margin-bottom: 112px!important;
        margin-left: 0px!important;
        margin-right: 0px!important;
        width: 100%;
        .gutter-row {
            .gutter-row-container{
                padding: 24px 16px 32px 16px;
                height: 100%;
                font-weight: 400;
                font-size: 16px;
                line-height: 32px;
                color: var(--text-color-inverse)!important;
                background-color: var(--landing-background-4);
                border: .5px solid var(--border); 
                .ant-typography{
                    color: var(--text-color-inverse)!important;
                    margin-bottom: 0px;
                }
                .number-container{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #E4E4E7;
                    text-align: center;
                    border-radius: 4px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 32px;
                    width: 32px;
                    height: 32px;
                    color: #71717A!important;
                }
            }
        }
    }

    .ant-tabs-tab-active{
        background: linear-gradient(102.33deg, #11998E -6.44%, var(--process-tail-color) 116.66%)!important;
        .ant-tabs-tab-btn{
            color: var(--text-color)!important;
        }
        
    }

    
    @media (min-width: 1440px) {
        /*  */
    }
    @media (max-width: 1439px) {
        /*  */
    }
    @media (max-width: 768px) {
        padding-left: 16px!important ;
        padding-right: 16px!important;
        .app-header-row{
            margin-top: 80px;
        }
        h2.ant-typography{
            font-weight: 600!important;
            font-size: 32px!important;
            line-height: 40px!important;
            color: var(--text-color-inverse)!important;
        }
        
    }
    @media (max-width: 425px) {
        padding-left: 16px!important ;
        padding-right: 16px!important;
        .app-header-row{
            margin-top: 80px;
        }
        h2.ant-typography{
            font-weight: 600!important;
            font-size: 32px!important;
            line-height: 40px!important;
            color: var(--text-color-inverse)!important;
        }
        
    }
    
`;