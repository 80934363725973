
import { Tabs } from "antd";
import { ProjectNamespace } from "../../_shared/namespaces/project";
import { ConnectButton } from "./connect-button";
import {  CoverContainer } from "./index.styled";
import { MyProjectTab } from "./my-project";
import { SacrificeProjectTab } from "./sacrifice-project";
const { TabPane } = Tabs;

interface MyProjectsProps {
  allProjects: ProjectNamespace.Project[]
  myProjects: ProjectNamespace.Project[]
  myProjectLoading: boolean
  myProjectPagination: any
  allProjectLoading: boolean
  loadingConnect: boolean
  address?: string
  handleOnNewProject: (data?:any)=>void
  handleSearchChange: (data:any)=>void
  handleMyProjectPagination: (page: number, pageSize:number)=>void
  handleConnect: ()=>void
}

export const MyProjects = (props: MyProjectsProps) => {
  const {myProjects, loadingConnect, myProjectLoading, 
        myProjectPagination,
        allProjects, allProjectLoading,address,
        handleConnect, handleOnNewProject,handleMyProjectPagination,
        handleSearchChange
      } = props;
  return (
        <CoverContainer>
          <Tabs defaultActiveKey="1" >
            <TabPane tab="Created Projects" key="1">
              { address && <MyProjectTab 
                  handleOnNewProject={handleOnNewProject} 
                  handleSearchChange={handleSearchChange} 
                  myProjects={myProjects} 
                  myProjectLoading={myProjectLoading}
                  onPaginationChange={handleMyProjectPagination}
                  pagination={myProjectPagination}

                  />}
              {!address &&<ConnectButton loading={loadingConnect} handleConnect={handleConnect}/>}
            </TabPane>

              
            <TabPane tab="My Sacrifices" key="2">
              {address && <SacrificeProjectTab 
                          handleOnNewProject={handleOnNewProject} 
                          handleSearchChange={handleSearchChange} 
                          allProjects={allProjects} 
                          allProjectLoading={allProjectLoading}/>}
              {!address &&<ConnectButton loading={loadingConnect} handleConnect={handleConnect}/>}

            </TabPane>
          </Tabs>  
          
        </CoverContainer>
  );
};