
import { Button, Space, Typography } from "antd";
import { toEther } from "../../../../../../web3/libs/utilities";
import { NETWORKS_TYPE } from "../../../../../../_shared";
import { AcceptedToken } from "../../../../../../_shared/utils/type";
const {Text} = Typography;
interface ListItemComponentProps{
  data: AcceptedToken
  currentNetwork?: NETWORKS_TYPE;
  selectedTokenAddress?: string
  loading: boolean
  loadingWithdrawToken:boolean
  handleRemoveTokenSubmit: ((data: any) => void)
  handleWidthdrawToken: ((data: any) => void)
  
}
export const ListItemComponent = (props: ListItemComponentProps) => {
    const {data, currentNetwork,loading, loadingWithdrawToken, selectedTokenAddress,handleWidthdrawToken, handleRemoveTokenSubmit} = props;
    return (
        <Space className="row-list-item" align="center">
            <Space className="title-container" direction="vertical">
            <Text className="name">{data.symbol===''?currentNetwork?.nativeToken:data.symbol} Sacrificed</Text>
            <Text className="price">{toEther(data.totalContributed??'')} {data.symbol===''?currentNetwork?.nativeToken:data.symbol}</Text>
            </Space>
            <Space className="btn-container">
            <Button  onClick={()=>{
                    handleWidthdrawToken(data)
                }}  loading={loadingWithdrawToken && selectedTokenAddress===data.token} shape="round" className="withdraw-btn">
                Withdraw
            </Button>
            {(currentNetwork?.usdAddress??'').toLowerCase() !== data.token.toLowerCase() && 
                <Button onClick={()=>{
                    handleRemoveTokenSubmit(data)
                }} loading={loading && selectedTokenAddress===data.token} shape="round" type="text" className="delete-btn">
                    Delete
                </Button>
            }
            
            </Space>
        </Space>
    );
  };