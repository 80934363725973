import { DeleteOutlined, PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, message, Select, Space, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import Upload, { RcFile } from "antd/lib/upload";
import { useEffect, useState } from "react";
import { REACT_APP_BASEURL, SOCIALS } from "../../../../../_shared";
import { ProjectNamespace } from "../../../../../_shared/namespaces/project";
import { FormWrapper } from "../index.styled";
const { Option } = Select;

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
};

interface FormOneComponentProps  {
    project: ProjectNamespace.Project
    loading: boolean
    onFinish?: ((values: any) => void) | undefined
}

export const FormOneComponent = (props: FormOneComponentProps) => {
    const {onFinish, project, loading} = props;
    const [loadingImg, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();
    const [form] = useForm();
    const initialValue = {
        title: project.title,
        website: project.website,
        logo: project.logo,
        description: project.description,
        socials: project.socials,
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
          setImageUrl('');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
          setImageUrl('');
        }
        return isJpgOrPng && isLt2M;
      };
    const normFile = (e: any) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        if (e?.file?.status === 'done') {
            // Get this url from response in real world.
            getBase64(e?.file?.originFileObj as RcFile, url => {
              setLoading(false);
              setImageUrl(url);
            });
          }
        if (e?.file?.status === 'uploading') {
            setLoading(true);
        }
        return e?.file?.response?.data?.url;
    };

    const uploadButton = (
        <div>
          {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      );
    
    useEffect(() => {
        form.setFieldsValue(initialValue)
        setImageUrl(project.logo)
    }, [project]);
    return (
            <FormWrapper>
                <Form
                    name="form_one"
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    autoComplete="off"
                    initialValues={initialValue}
                    >
                    <Form.Item
                        label="Project Title"
                        name="title"
                        requiredMark={'optional'}
                        rules={[{ required: true,message: 'Please input your project title!' }]}
                    >
                        <Input placeholder="e.g GameBoy" />
                    </Form.Item>

                    <Form.Item
                        label="Website URL"
                        name="website"
                        requiredMark={'optional'}
                        rules={[{ required: true,  type:'url',message: 'Please input your project website!' }]}
                    >
                        <Input placeholder="" />
                    </Form.Item>

                    <Form.Item
                            name="logo"
                            label="Logo"
                            requiredMark={'optional'}
                            getValueFromEvent={normFile}
                            rules={[{ required: true,  type:'url',message: 'Please upload your project logo!' }]}
                        >
                        <Upload
                            name="file"
                            disabled={loading}
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={`${REACT_APP_BASEURL}/media`}
                            beforeUpload={beforeUpload}
                            >
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ height: '100%'}} /> : uploadButton}
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        name="description"
                        label="Description"
                        requiredMark={'optional'}
                        rules={[{ required: true, message: 'Please input Description' }]}
                    >
                        <Input.TextArea rows={4} showCount maxLength={80} />
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <span className="ant-form-text">Social Media</span>
                        </Space>
                        
                    </Form.Item>
                    
                    <Form.List
                        name="socials"
                        rules={[
                        {
                            validator: async (_, links) => {
                            if (!links || links.length < 1) {
                                return Promise.reject(new Error('At least 1 Social Media'));
                            }
                            },
                        },
                        ]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            
                        <>
                            {fields.map((field, index) => (
                                <Space key={field.key +index} className="social-media-items" align="center" >
                                    
                                    <Form.Item
                                    {...field}
                                    label="Name"
                                    key={'name' +index}
                                    name={[field.name, 'name']}
                                    requiredMark={'optional'}
                                    rules={[{ required: true, message: 'Missing Social Media' }]}
                                    >
                                    <Select
                                        placeholder="Select a social media"
                                        allowClear
                                    >
                                        {SOCIALS.map((social, index)=>{
                                            return <Option key={index}  value={social.key}>{social.label}</Option>
                                        })}
                                    </Select>
                                    
                                    </Form.Item>
                                    <Form.Item
                                    {...field}
                                    label="Url"
                                    key={'url' +index}
                                    name={[field.name, 'url']}
                                    requiredMark={'optional'}
                                    rules={[{ required: true,  type:'url', message: 'Missing Url' }]}
                                    >
                                    <Input placeholder="" />
                                    </Form.Item>
                    
                                    {fields.length > 1 ? (
                                        <DeleteOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                        />
                                        ) : null}
                                </Space>
                            ))}
                            <Form.Item>
                                <Button
                                    type="text"
                                    onClick={() => add()}
                                    style={{ marginLeft: 'auto' }}
                                    icon={<PlusOutlined />}
                                >
                                    Add Another Social
                                </Button>
                            </Form.Item>
                            <div>
                                <Form.ErrorList errors={errors} />
                            </div>
                        </>
                        )}
                    </Form.List>

                    <Divider />

                    

                    <Form.Item>
                        <Button loading={loading} shape="round" className="next-btn" type="primary" htmlType="submit">
                            Save Project Settings
                        </Button>
                    </Form.Item>
                </Form>
            </FormWrapper>
    );
  };    