import { DeleteFilled } from "@ant-design/icons";
import { Button, Col, Row, Space, Typography } from "antd";
import { VolumeBonusWrapper } from "./index.styled";
const {Text} = Typography;
interface ListDataItem{
  contributionAmount: string;
  percentage: string;
}
interface VolumeBonusComponentProps{
  listData: ListDataItem[];
  loading: boolean;
  handleNewVolumeBonus : (data?:any)=>void
  handleManageVolumeBonus: ((data: any, type:string) => void)

  
}
export const VolumeBonusComponent = (props: VolumeBonusComponentProps) => {
    const {listData,loading, handleNewVolumeBonus, handleManageVolumeBonus} = props;    
    const renderListData = listData.map((data, key)=>(
      <>
      <Row key={key}  className="row-list-item">
        <Col xs={24} md={8}>
          <Text className="">{data.contributionAmount}</Text>
        </Col>
        <Col xs={24} md={8}>
          <Text className="">{data.percentage}%</Text>
        </Col>
        <Col xs={24} md={8}>
          <Space className="btn-container">
            <Button onClick={()=>{
                handleManageVolumeBonus({...data, index:key}, 'set-selected')
                handleNewVolumeBonus()
                }} 
                shape="round" className="withdraw-btn">
              Edit
            </Button>
            <Button shape="round"
              onClick={()=>{
                handleManageVolumeBonus({...data, index:key}, 'remove-selected')
                }} 
             type="text" icon={<DeleteFilled />} className="delete-btn"/>
          </Space>
        </Col>
      </Row>
      </>
    ))
    return (
          <VolumeBonusWrapper>

            <div className="hint" >
            <Text>
              Encourage higher donations by rewarding donors for sacrificing higher amounts
            </Text>
            </div>
            <Space className="top-bar" align="center">
                
                <Button loading={loading} onClick={()=>{
                        handleManageVolumeBonus(null, 'set-selected')
                        handleNewVolumeBonus()
                        }} 
                  shape="round" className="new-group-btn">
                  New Volume Bonus
                </Button>
            </Space>
            <Space className="list-container" direction="vertical">
              <Row>
                <Col xs={24} md={8}>
                  <Text className="muted">USD Amount Donated</Text>
                </Col>
                <Col xs={24} md={8}>
                  <Text className="muted">Bonus</Text>
                </Col>
              </Row>
              {renderListData}
            </Space>
          </VolumeBonusWrapper>
    );
  };