

import { Button, Space} from "antd";

import { SignButtonWrapper} from "../index.styled";

interface SignAddressButtonProps {
  handleClick: ()=>void
  loading: boolean
  address?: string
}

export const SignAddressButton = (props: SignAddressButtonProps) => {
  const {handleClick, loading, address} = props;
  return (
    <SignButtonWrapper >
      <div>
      <Space direction="vertical" size="large" >
      <img width={50} src="/pulsesac_logo.svg" alt="...." /> 
      </Space>
      </div>
      <Space direction="vertical" size="large" >
      <h3 className="mt-5">Welcome</h3>
      </Space>
      <div>
      <h2>{address}</h2>
      </div>

      {/* <div>
        Continuing implies you have read and agree to our <a href='/terms' target={'_blank'}>terms of service</a>
      </div> */}
      
        <Button size="large" loading={loading} onClick={handleClick} type="primary"  shape="round">Click to Continue</Button>
    </SignButtonWrapper>
  );
};