
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column ;
    background-color: var(--landing-background);
    overflow-x: hidden;
    /* background-image: url("./r-bg.png");  */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    .ant-breadcrumb{
        margin-bottom: 10px!important;
        .ant-breadcrumb-link, .ant-breadcrumb-separator{
            color: var(--accent)!important;
        }
        
    }
`;
