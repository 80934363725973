import styled from "styled-components";

export const Container = styled.div`
  /* background: var(--landing-background); */
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 120px !important;
  padding-right: 120px !important;
  gap: 24px;
  position: relative;

  .side-social {
    transform: rotate(-90deg);
    position: absolute;
    top: 300px;
    right: -85px;
    span.ant-typography {
      font-weight: 300 !important;
      font-size: 16px !important;
      line-height: 28px !important;
      color: var(--disabled-color) !important;
    }
  }

  .what-is-it {
    margin-top: 162px;
  }
  .project-tabs {
    margin-top: 64px !important;
    margin-bottom: 112px !important;
  }
  .project-owner-row {
    margin-top: 48px;
  }

  h1.ant-typography {
    font-size: 56px !important;
    line-height: 80px !important;
    letter-spacing: 0.02em !important;
  }

  h2.ant-typography {
    font-weight: 600 !important;
    font-size: 40px !important;
    line-height: 40px !important;
  }

  .m-bullet {
    font-size: 2em;
    color: var(--checkbox-color);
  }
  .m-point-text {
    width: 676px;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
  }
  .get-started-container {
    button.ant-btn {
      padding: 16px 32px;
      height: auto;
      transition: 0.5s;
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
  .ant-tabs-ink-bar {
    background: transparent !important;
  }
  .linear-bg {
    background: linear-gradient(
      102.33deg,
      #11998e -6.44%,
      var(--checkbox-color) 116.66%
    ) !important;
    border-color: transparent !important;
  }
  .ant-btn {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 26px !important;
  }
  .ant-tabs-tab-btn {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center;
    padding: 4px 32px !important;
    gap: 8px !important;
    width: 221px !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 26px !important;
  }
  .ant-tabs {
    width: 100%;
  }
  .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      justify-content: start !important;
    }
    .ant-tabs-nav-list {
      background-color: var(--landing-background-2);
      overflow-x: auto;
      width: fit-content;

      ::-webkit-scrollbar {
        height: 10px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: var(--landing-background-3);
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: var(--landing-background);
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: var(--landing-background-2);
      }
    }

    ::before {
      border: 0 !important;
    }
  }

  .ant-tabs-tabpane {
    .gutter-row div {
      height: 100%;
      padding: 24px 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      background-color: var(--landing-background-3);
      color: var(--text-color-1);
      border: 0.5px solid var(--border);
    }
  }

  .ant-tabs-tab-active {
    background: linear-gradient(
      102.33deg,
      #11998e -6.44%,
      var(--checkbox-color) 116.66%
    ) !important;
    .ant-tabs-tab-btn {
      color: var(--landing-background-inverse) !important;
    }
  }

  @media (min-width: 1440px) {
    /*  */
  }
  @media (max-width: 1439px) {
    .m-point-text {
      font-weight: 300;
      font-size: 18px;
      line-height: 28px;
    }
  }
  @media (max-width: 768px) {
    padding-left: 16px !important ;
    padding-right: 16px !important;
    h1.ant-typography {
      font-weight: 500 !important;
      font-size: 28px !important;
      line-height: 30px !important;
      letter-spacing: 0.02em !important;
      margin-top: 18px !important;
    }

    h2.ant-typography {
      font-weight: 500 !important;
      font-size: 28px !important;
      line-height: 10px !important;
    }

    .m-point-text {
      width: auto;
      font-weight: 300;
      font-size: 18px;
      line-height: 28px;
    }
    .what-is-it {
      margin-top: 112px;
    }
    .project-tabs {
      margin-top: 20px !important;
      margin-bottom: 60px !important;
    }
    .project-owner-row {
      margin-top: 24px;
    }
  }
  @media (max-width: 425px) {
    padding-left: 16px !important ;
    padding-right: 16px !important;

    .side-social {
      display: none !important;
    }
    h1.ant-typography {
      font-weight: 500 !important;
      font-size: 28px !important;
      line-height: 46px !important;
      letter-spacing: 0.02em !important;
      margin-top: 18px !important;
    }

    h2.ant-typography {
      font-weight: 500 !important;
      font-size: 28px !important;
      line-height: 10px !important;
    }

    .m-point-text {
      width: auto;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
    }

    .what-is-it {
      margin-top: 112px;
    }

    .project-tabs {
      margin-top: 20px !important;
      margin-bottom: 60px !important;
    }

    .project-owner-row {
      margin-top: 24px;
    }
  }
`;
