import axios from "axios";
import {
  PUBLIC_API_URL,
  SIGNED_ADDRESS_KEY,
  SIGNED_MESSAGE,
} from "../../constants";
import { Storage } from "../storage";
// import { SIGNED_ADDRESS_KEY, SIGNED_MESSAGE, Storage } from "../..";

// Default config options
const defaultOptions = {
  baseURL: PUBLIC_API_URL,
};

// Update instance
const instance = axios.create(defaultOptions);

// Set the AUTH token for any request
instance.interceptors.request.use(
  (config: any) => {
    if (!config.isExternal) {
      const storage = new Storage(SIGNED_ADDRESS_KEY);
      config.headers["x-access-token"] = JSON.stringify({
        message: SIGNED_MESSAGE,
        signature: storage.get()?.result,
        timestamp: storage.get()?.timestamp,
        user: storage.get()?.address,
        signedMessage: storage.get()?.signedMessage,
      });
      config.headers["x-api-key"] = "PulseSac";
    }

    return config;
  },
  (error: any) => {
    // Do something with request error
    return Promise.reject(error);
  }
);
// Add a response interceptor
instance.interceptors.response.use(
  (response: any) => {
    // Do something with response data
    return response.data;
  },
  (error: any) => {
    // Do something with response error
    return Promise.reject(error.response);
  }
);

export const createApiRequest = (config: any) => instance(config);

export default createApiRequest;

export { instance };
