
import { Typography, Row, Col, Tabs, Collapse } from "antd";
const {Title } = Typography;
const { Panel } = Collapse;

const { TabPane } = Tabs;

interface FaqTabContentProps{
    title: string,
    data: {
        question: string,
        answer: string,
    }[],
}
interface FaqsSectionProps {
    text?: string;
    faqTabContent: FaqTabContentProps[]
}

export const FaqsSection = (props: FaqsSectionProps) => {
    const { faqTabContent } = props;


    const renderFaqsItems = faqTabContent.map((tabContent, key)=>{
        const renderItemList = tabContent.data.map((item, k)=>{
            return (
                <Col key={`${key}-${k}`} xs={24} md={12}>
                    <Collapse defaultActiveKey={k==0?['0']:''}>
                        <Panel header={item.question} key={key}>
                            <p>{item.answer}</p>
                        </Panel>
                    </Collapse>
                </Col>
            )
        })
        return (
            <TabPane tab={tabContent.title} key={key}>
                <Row gutter={[40,24]}>
                    {renderItemList}
                </Row>
            </TabPane>
        )
    })

    return (<>
            

            <Row className="ant-row-center faqs-title-container" id="faqs">
                <Title level={2} style={{}}>
                    Frequently Asked Questions
                </Title>
                {/* <Col span={24} className="m-point-text"><Text>Enter Brief Description here</Text></Col> */}
            </Row>
            <Tabs className="faqs-tab" defaultActiveKey="0" centered >
                {renderFaqsItems}
            </Tabs>

            
        </>)
};


