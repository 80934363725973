
import styled from "styled-components";

export const H1Styled = styled.h1`
    font-size: 1.85em!important;
    line-height: 1!important;
    color: var(--text-color)!important;
    font-weight: 300!important;
`;


export const TitleStyled = styled.h2`
    font-weight: 400!important;
    margin-bottom: 0px!important;
    font-size: 1em!important;
    color: var(--accent)!important;
    height: 2rem;
`;

export const UtilContainer = styled.div`
    margin-bottom: 2rem !important;
`;

export const UtilCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 36px 24px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: var(--foreground);
    .ant-input, .ant-input-group-addon{
        border-color: var(--background-primary)!important;
    }

    input::placeholder { /* Most modern browsers support this now. */
        color: var(--disabled-color)!important;
    }
`;
export const SignButtonWrapper = styled.div`
  display: block;
  width: 100%;
  align-items: center;
  justify-content:  center;
  margin: auto auto;
  text-align: center;
  padding-top: 5%;
    img {
        margin-bottom: 2vh;
    }
  .ant-btn{
    margin-top: 5vh;

  }
`;