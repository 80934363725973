import { ShareAltOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Row, Skeleton, Typography } from "antd";
import { toEther } from "../../../../web3/libs/utilities";
import { computeEndDate, moneyFormat } from "../../../../_shared";
import { ProjectNamespace } from "../../../../_shared/namespaces/project";
import { SacrificeDetailContainer } from "./index.styled";
const { Text } = Typography;
interface SacrificeDetailProps {
  project?: ProjectNamespace.Project;
  sacrificePhaseDay?: string
  handleShareButton: (() => void)
  usdDecimals: number
}
export const SacrificeDetail = (props: SacrificeDetailProps ) => {
  const {project:_project,sacrificePhaseDay, usdDecimals, handleShareButton} = props;
  const project = _project??{} as ProjectNamespace.Project;
  return (
    
    <SacrificeDetailContainer>
      <Skeleton title={{
            style:{
              display:"inline-block",
            }
          }}
          
        loading={!Boolean(project.id)} active>
        <Avatar shape="square" size={64} icon={<UserOutlined />} src={project.logo} />
        <Text className="date">{(new Date(project.startDayTime * 1000)).toDateString()
                    } - {(new Date(computeEndDate(project.startDayTime, project.phases))).toDateString()
                    }</Text>
        <div className="name-container">
          <Text className="name">{project.title}</Text>
          {Boolean(project.isKYCed) && <img src="/verified.svg" alt="...." />}
          <Button className="share-button" size="large" onClick={handleShareButton} type="text"><ShareAltOutlined /></Button>
        </div>
        {(sacrificePhaseDay && sacrificePhaseDay!=='0' ) && <Text className="day">Day {sacrificePhaseDay}</Text>}
        <Row  className="total-value" justify="space-between">
          <Col>
            <Text className="date">Total Raised Value</Text>
          </Col>
          <Col>
            <Text className="day">${moneyFormat(toEther(String(project.totalSacrificedValue??0), 2), 2)}</Text>
          </Col>
        </Row>
      </Skeleton>
    </SacrificeDetailContainer>
    
  );
};
