
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    .ant-breadcrumb{
        margin-bottom: 10px!important;
        .ant-breadcrumb-link, .ant-breadcrumb-separator{
            color: var(--accent)!important;
        }
        
    }

    @media (max-width: 768px) {
        max-width: 90%;
    }
`;
