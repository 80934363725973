import { useSacrifice } from "../../hooks/useSacrifice";
import { computeEndDate, NETWORKS_TYPE } from "../../_shared";
import { ProjectNamespace } from "../../_shared/namespaces/project";
import { AcceptedToken, UserDataStat } from "../../_shared/utils/type";
import { ShareModal } from "../view-project/libs/modal/share";
import { WrapperContainer, WrapperPrimaryContainer, WrapperSecondaryContainer } from "./index.styled";
import { CountDown } from "./libs/count-down";
import { CurrentRatePoint } from "./libs/current-rate-point";
import { ProjectDetails } from "./libs/project-details";
import { SacrificeAddress } from "./libs/sacrifice-address";
import { SacrificeDetail } from "./libs/sacrifice-detail";
import { DesktopMenuDetail, DesktopMenuDetailTwo, MobileMenuDetail } from "./libs/side-menu";
interface SacrificeComponentProps {
  project?: ProjectNamespace.Project|any;
  sacrificePhaseDay?: string
  pointRate?: string
  sacAddress?: string
  donationPoints?: string
  currentNetwork?: NETWORKS_TYPE
  onComplete: (() => void),
  handleShareButton: (() => void),
  
  handleSwitchNetwork: ((any?: any) => void)

  provider: any,
  usdDecimals: number,
  // handleAmountNetworkChange: (chaindId: string,amount: string, referalAddress?:string)=>void
   handleOnFinishSacrifice: (data?:any)=>void

 
  shareData: {
    visible: boolean
    onClose?: () => void
    onCopied?: (data?: any) => void
    text?: string
  }
  acceptedTokens?: AcceptedToken[]
  userDataStats?: UserDataStat
  // bonusData?: GetBonusStat
  loading: boolean
  loadingNetworkSwitch:boolean
  deployToggle: boolean
  uiLoaders: Record<any, boolean>
}
export const SacrificeComponent = (props: SacrificeComponentProps ) => {
  const {project, shareData, sacrificePhaseDay,pointRate,sacAddress,acceptedTokens,
        userDataStats,loading,currentNetwork, loadingNetworkSwitch,
        uiLoaders,deployToggle,
     handleShareButton, handleOnFinishSacrifice, handleSwitchNetwork, onComplete, usdDecimals, provider} = props;

   
  const {sacData, onSacrifice, referralError, tokenBalance, amountError, bonusData, loadingBonuses,  handleAmountNetworkChange} = useSacrifice({
      key: '@@KEY',
      sacAddress,
      project,
      handleOnFinishSacrifice,
      provider,
      usdDecimals
    });


  let currentRatePointRender = <></>
  const startDate  = new Date(project.startDayTime*1000);
  const endDate  = new Date(computeEndDate(project.startDayTime, project.phases));
  if(project && Date.now() > endDate.getTime()){
    currentRatePointRender = <><h1>Ended</h1></>
  }
  if(project.id && Date.now() < startDate.getTime()){
    currentRatePointRender = <CountDown
      onComplete={onComplete}
      project={project}
    />
  }
  if(project.id && Date.now() > startDate.getTime() && Date.now() < endDate.getTime()){
    currentRatePointRender = <CurrentRatePoint 
      handleSwitchNetwork={handleSwitchNetwork}
      loading = {loadingBonuses}
      loadingNetworkSwitch={loadingNetworkSwitch}
      deployToggle={deployToggle}
      userDataStats={userDataStats}
      sacData={sacData}
      project={project}
      amountError={amountError}
      uiLoaders={uiLoaders}
      handleChange={handleAmountNetworkChange}
      pointRate={pointRate}
      acceptedTokens={acceptedTokens}
      bonusData={bonusData}
      referralError={referralError}
      currentNetwork={currentNetwork}
      handleOnFinish={onSacrifice}
      tokenBalance={tokenBalance}
      usdDecimals={usdDecimals}
      />
  }
     
  return (
        <WrapperContainer>
            <SacrificeDetail
              project={project}
              sacrificePhaseDay={sacrificePhaseDay}
              handleShareButton={handleShareButton}
              usdDecimals={usdDecimals}
              />
            <WrapperPrimaryContainer>
              <DesktopMenuDetail/>
              <WrapperSecondaryContainer>
                  <MobileMenuDetail 
                    loading={loading}
                    userDataStats={userDataStats}/>
                  {currentRatePointRender}
                  <SacrificeAddress
                    project={project}
                    sacAddress={sacAddress}
                    sacrificePhaseDay={sacrificePhaseDay}
                    />
                  <ProjectDetails
                    project={project}
                    />
                  
                  {/* <ClaimYourToken/> */}
              </WrapperSecondaryContainer>
              <DesktopMenuDetailTwo
                loading={loading}
                userDataStats={userDataStats}/>
            </WrapperPrimaryContainer>
            

            <ShareModal {...shareData}/>
            
        </WrapperContainer>
  );
};
