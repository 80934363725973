import {  Button, Divider, Space, Tabs, Typography } from "antd";
import { useState } from "react";
import { ProjectNamespace } from "../../_shared/namespaces/project";
import { ConnectButton } from "../project/connect-button";
import { EditProjectWrapper } from "./index.styled";
import { FormOneComponent, FormTwoComponent, FormThreeComponent } from "./lib/form";
import { StepComponent } from "./lib/steps";
const { Text } = Typography;
const { TabPane } = Tabs;

interface EditProjectComponentProps  {
  project: ProjectNamespace.Project
  onComplete?: ((values?: any) => void) | undefined
  handleBackArrow: ((values?: any) => void)
  _OnSubmitFormOne: ((values?: any) => void) 
  _OnSubmitFormTwo: ((values?: any) => void) 
  loading: boolean
  loadingConnect: boolean
  address?: string
  handleConnect: ()=>void
  totalPhaseDays: number
  
}


export const EditProjectComponent = (props: EditProjectComponentProps) => {
  const {onComplete, handleBackArrow, _OnSubmitFormOne, _OnSubmitFormTwo, 
    project, loadingConnect, loading, address, totalPhaseDays, handleConnect} = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const onSubmitFormOne =  (data: any)=>{
    _OnSubmitFormOne(data);
  }

  const onSubmitFormTwo =  (data: any)=>{
    _OnSubmitFormTwo(data)
  }

  const onSubmitFormComplete =  (data: any)=>{
    if(onComplete)onComplete(data);
  }


  const handleBackButton = () =>{
    if(currentIndex > 0) {
      setCurrentIndex(currentIndex-1);
    }else{
      handleBackArrow()
    }
  }
  const handlePrevButton = () =>{
    if(currentIndex > 0) {
      setCurrentIndex(currentIndex-1);
    }
  }
  const handleNextButton = () =>{
    if(currentIndex < 2) {
      setCurrentIndex(currentIndex+1);
    }
  }
  return (
        <EditProjectWrapper>
          <Space className="main-container" direction="vertical">
            <Space className="header-row" align="center" >
              <Button className="back" onClick={handleBackButton} icon={
                <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.5 7H20.5M1.5 7L7.54545 1.5M1.5 7L7.54545 12.5" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>
              }/>
              <Space>
                {currentIndex?<Button type="primary" shape="round" onClick={handlePrevButton}>
                  Previous
                </Button>:''}
                {currentIndex<2?<Button  type="primary" shape="round" onClick={handleNextButton}>
                  Next
                </Button>:''}
              </Space>
            </Space>
            <Space className="title-row" align="center" >
              <Text className="title-text">Update Sacrifice Project</Text>
              <StepComponent length={3} currentIndex={currentIndex}/>
            </Space>
            <Divider className="header-divider"/>
            {address && <Tabs activeKey={currentIndex+''} renderTabBar={()=><></>} >
              <TabPane tab={null} key="0">
                <Space className="form-container">
                  <FormOneComponent 
                    project={project} 
                    loading={loading}
                    onFinish={onSubmitFormOne}/>
                </Space>
              </TabPane>
              <TabPane tab={null} key="1">
                <Space className="form-container">
                  <FormTwoComponent 
                    project={project}
                    loading={loading}
                    onFinish={onSubmitFormTwo}
                    handlePrevious={handleBackButton}
                    />
                </Space>
              </TabPane>
              <TabPane tab={null} key="2">
                <Space className="form-container">
                    <FormThreeComponent
                      project={project}
                      onFinish={onSubmitFormComplete}
                      handlePrevious={handleBackButton}
                      loading={loading}
                      totalPhaseDays={totalPhaseDays}
                      />
                  </Space>
              </TabPane>
            </Tabs>}
            {!address &&<ConnectButton loading={loadingConnect} handleConnect={handleConnect}/>}
            

          </Space>
        </EditProjectWrapper>
  );
};