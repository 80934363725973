
import { Avatar, Button, List, Skeleton, Space, Typography} from "antd";
import { Link } from "react-router-dom";
import { toEther } from "../../../web3/libs/utilities";
import { computeEndDate, moneyFormat } from "../../../_shared";
import { ProjectNamespace } from "../../../_shared/namespaces/project";
const { Text } = Typography;

interface ProjectItemProps {
  project: ProjectNamespace.Project
  loading: boolean
}

export const ProjectItem = (props: ProjectItemProps) => {
  const {project, loading} = props;
  let totalSacrificedValue= '0';
  try {
    totalSacrificedValue = moneyFormat(toEther(project.totalSacrificedValue??'0'));
  } catch (error) {
    // console.log(`totalSacrificedValue`, totalSacrificedValue, item)
  }
  return (
        <List.Item
        >
          <Skeleton avatar title={false} loading={loading} active>
              <List.Item.Meta
              avatar={<Avatar src={project.logo} shape="square" size={60} />}
              title={<Text className="disable">{(new Date(project.startDayTime * 1000)).toDateString()
              } - {(new Date(computeEndDate(project.startDayTime, project.phases))).toDateString()
              }</Text>}
              description={<Text>{project.title}</Text>}
              />
              <Space className="content-space" align="center">
                <Text>${totalSacrificedValue}</Text>
                <Space>
                  <Link to={`/projects/${project.id}/view`}>
                    <Button shape="round">Manage Project</Button>
                  </Link>
                </Space>
              </Space>
          </Skeleton>
        </List.Item>
    );
};