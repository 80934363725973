import styled from "styled-components";

export const EditProjectWrapper = styled.div`
    display: flex!important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 4px !important;
    gap:8px;
    .main-container, .header-row, .title-row, .form-container{
        width: 100%;
    }
    .form-container .ant-space-item{
        display: flex!important;
        width: 100%; 
        
    }
    .main-container{
        gap:16px!important;
    }
    .header-row{
        justify-content: space-between;
        align-items: center;

        .ant-btn.back{
            border: 0!important;
            background-color: transparent!important;
            path{
                /* display: none!important;; */
                stroke: var(--text-color);
            }
        }
    }
    .title-row{
        justify-content: space-between;
        align-items: center;
        .title-text{
            font-weight: 500;
            font-size: 25px;
            line-height: 33px;
        }
    }

    .ant-divider.header-divider{
        border-top-color: var(--border)!important;
        margin: 0!important;
    }
    .anticon-info-circle{
        color: var(--disabled-color)!important;
    }
    
    
`;
