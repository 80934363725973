
import {  Button, Form, Input, List } from "antd";
import { ProjectNamespace } from "../../../_shared/namespaces/project";
import { ProjectItem } from "../../utils";
interface SacrificeProjectTabProps{
  allProjects: ProjectNamespace.Project[]
  allProjectLoading: boolean
  handleOnNewProject: (data?:any)=>void
  handleSearchChange: (data:any)=>void
}

export const SacrificeProjectTab = (props: SacrificeProjectTabProps) => {
  const {allProjects, allProjectLoading, handleOnNewProject, handleSearchChange} = props;
  
  

  return (
    <>
        <Form  name="horizontal_form" layout="inline" >
        <Form.Item
            className="search-form-item"
            name="username"
            
        >
            <Input onChange={handleSearchChange} placeholder="Search Project Name" />
        </Form.Item>
        
        

        {/* <Form.Item className="add-form-item">
            <Button
                type="primary"
                htmlType="button"
                shape="round"
                onClick={handleOnNewProject}
            >
                Add Sacrifice
            </Button>
        </Form.Item> */}
        </Form>
        <List
        className="demo-loadmore-list"
        
        loading={allProjectLoading}
        itemLayout="horizontal"
        // loadMore={loadMore}
        dataSource={allProjects}
        renderItem={project => <ProjectItem loading={allProjectLoading} project={project}/>}
        />
    </>
  );
};