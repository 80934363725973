import { message } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SacrificeComponent } from "../../components";
import { WalletConnectorContext } from "../../contexts";
import { useProject, useSacProxy, useUIState, useUser } from "../../hooks";

import { copyToClipboard, getCurrentFullUrl, NETWORKS_TYPE, resolveCurrentNetworkObject } from "../../_shared";
import { ProjectNamespace } from "../../_shared/namespaces/project";
import { UserNamespace } from "../../_shared/namespaces/user";
import { UserDataStat } from "../../_shared/utils/type";
import { Container } from "./index.styled";

export const ViewSacrificeContainer = () => {


  const {address, chainId, switchNetwork, provider, loadingSigning} = useContext(WalletConnectorContext);
  const KEY = `@@view-a-sac`;
  
  const WEB3_KEY = `@@view-a-sac-web-3`;
  const MY_KEY = `@@view-a-sac-me`;
  const { uiLoaders } = useUIState();

  const {id} = useParams();
  const loading = uiLoaders[KEY];

  
  

  const [project, setProject] = useState<ProjectNamespace.Project>();

  const [userDataStats, setUserDataStats] = useState<UserNamespace.User>();
  

  const [toggle, setToggle] = useState(false);
  

  
  const { handleGet } = useProject({
    key: KEY,
  });


  const { handleGet: handleGetUser} = useUser({
    key: MY_KEY,
  });

  const {sacrificePhaseDay,  address: sacAddress, pointRate,
    
    acceptedTokens,
    getSacrificePhaseDay, getSacrificeContractAddress,
    getPointRate, getAcceptedTokens, getUsdDecimals, usdDecimals
   
  } = useSacProxy({
  key:WEB3_KEY,
  address,
  provider
  });
  

  const [setOpenShare, setSetOpenShare] = useState(false);
  const [currentNetwork, setCurrentNetwork] = useState<NETWORKS_TYPE>();
  const [loadingNetworkSwitch, setLoadingNetworkSwitch] = useState(false);

  

  useEffect(() => {
    getUsdDecimals();
    if(id) handleGet(`${id}/signature`, 
      {
        onFinish: (data: any)=>{
          setProject(data)
        }
      }
    );
  }, [id]);

 
  useEffect(() => {
    console.log(`-----address`, address)
  }, []);


  const loadData = useCallback((id?: string) => {
    if(id) handleGet(`${id}/signature`, 
      {
        onFinish: (data: any)=>{
          setProject(data)
        }
      }
    );
    
    if(address) handleGetUser(`${address}/project/${id}`, {
      onFinish: (data: any)=>{
        setUserDataStats(data)
      }
    });

  }, [address, handleGet]);

  useEffect(() => {
     loadData(id)
  }, [address]);

  useEffect(() => {
    setTimeout(()=>{
      loadData(id)
    }, 3000);
  }, [ toggle]);
  
  useEffect(() => {
   
    if(project && project.signature){
      getSacrificeContractAddress( `${project.id}`, project.contractVersion)
    }
  }, [project, provider]);

  useEffect(() => {

    if(sacAddress && provider){
      getSacrificePhaseDay()
      getPointRate();
      getAcceptedTokens();
       // getUsdDecimals();
    }
  }, [ provider, sacAddress]);

  useEffect(() => {
    if(chainId) {
      setCurrentNetwork(resolveCurrentNetworkObject(`${chainId}`));
      getAcceptedTokens();
      if(sacAddress) {
        getUsdDecimals();
      }
    }
  }, [chainId]);

  if(loadingSigning){
    return <h1>Please Sign on metamasK</h1>
  }
  
  const handleOnClose = ()=>{
    setSetOpenShare(false)
  }

  const handleOnCopy = ()=>{
    copyToClipboard(`${getCurrentFullUrl()}`);
    message.success('copied'); 
  }
  const handleShareButton = ()=>{
    setSetOpenShare(true)
  }
  

  const handleOnFinishSacrifice = () => {
    setToggle(old=>!old)
  };

  const handleSwitchNetwork = async (data:any)=>{
    setLoadingNetworkSwitch(true)
    switchNetwork?.(data.chainId).then(()=>{
      setLoadingNetworkSwitch(false)
    }).catch(_=>setLoadingNetworkSwitch(false))
    
    
  }

  const onCountDownComplete = ()=>{
    setToggle(old=>!old)
  }


  const shareData = {
    visible: setOpenShare,
    onClose: handleOnClose,
    onCopied: handleOnCopy,
    text: getCurrentFullUrl()
  }

  const dataStates = {
    project: {...project, fullUrl: getCurrentFullUrl() } ,
    sacrificePhaseDay,
    pointRate,
    currentNetwork,
    sacAddress,
    acceptedTokens,
    deployToggle: toggle,
    loadingNetworkSwitch,
    provider,
    userDataStats: (userDataStats as unknown as UserDataStat),
    loading: loading,
    usdDecimals: Number(usdDecimals??0),
  }


  return (
    <Container>
          <SacrificeComponent
            {...dataStates}
            handleShareButton={handleShareButton} 
            // handleAmountNetworkChange={handleAmountNetworkChange}
            handleOnFinishSacrifice={handleOnFinishSacrifice}
            handleSwitchNetwork={handleSwitchNetwork}
            onComplete={onCountDownComplete}
            shareData={shareData}
            uiLoaders={uiLoaders}
            />
    </Container>
  );
};
