import styled from "styled-components";

export const CoverContainer = styled.div`
    width: 100%;

    gap: 20px;
    .disable{
        color: var(--disabled-color)!important;
    }  
    .muted{
        color: var(--accent2)!important;
        
    }
    .bg-success{
        background-color: var(--accent)!important;
        border-color: var(--accent)!important;
    }
    .ant-divider{
        margin-top: 0px!important;
        margin-bottom: 0px!important;
        border-color: var(--border)!important;
    }
    .title-text{
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        &-1{
            font-size: 18px;
        }
    } 
    
    .select-after, .ant-select-selector{
        /* background-color: var(--accent)!important; */
        border-radius: 4px!important;
        color: #fff!important;
    }

    .ant-tabs-nav-wrap, .ant-tabs-nav{
        width: fit-content;
    }
    .ant-tabs-tab-btn{
        color: var(--disabled-color)!important;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
    }
    .ant-tabs-ink-bar{
        background: var(--process-tail-color)!important;
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn{
        color: var(--text-color)!important;
    }

    .ant-btn{
        height: 48px!important;
    }
    .add-form-item {
        margin-left: auto!important;
        .ant-btn{
            background: #16A34A!important;
            border: 1px solid #16A34A;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
    }

    .ant-input{
        ::placeholder {
            color: var(--disabled-color)!important;
        }
        height: 48px;

        background: #0D0D2D;
        /* FormStroke */

        border: 1px solid #363665;
        border-radius: 4px;
    }
    .search-form-item{
        width: 379px;
    }

    .ant-dropdown-button{
        .ant-btn {
            background-color: #3C3CC7!important;
            :first-child {
                
                border-right: 0px;
                
            }
            :last-child {
                padding: 0px!important;
                border-left: 0px;
                
            }
        }
    }

    .ant-tabs-top{
        gap: 30px;
        margin-bottom: 70px!important;
    }
    .ant-list-item{
        align-items: center;
        .ant-list-item-meta{
            align-items: center;
            flex: auto;
            width: 50%;
            &.sacrifice-space{
                width: 30%;
            }
        }
        .content-space{
            width: 50%;
            justify-content: space-between;
            &.sacrifice-space{
                width: 60%;
            }
            .ant-typography{
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
            }
            .ant-btn{
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
            }
        }

        @media (max-width: 425px) {
            .ant-list-item-meta, .content-space{
                width: 100%;
            }
            
        }
    }

    .ant-list-item-meta-content{
        h4.ant-list-item-meta-title{
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
        }
        .ant-list-item-meta-description .ant-typography{
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
        }
    }

    #horizontal_form{
        margin-bottom:70px ;
        @media (max-width: 425px) {
            gap: 5px;
            flex-wrap: wrap;
            overflow-x: auto;
            ::-webkit-scrollbar {
                height: 10px;
            }

            /* Track */
            ::-webkit-scrollbar-track {
                background: var(--landing-background-3);
            }

            /* Handle */
            ::-webkit-scrollbar-thumb {
                background: var(--background-primary);
            }

            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
                background: var(--landing-background-2);
            }
            .ant-form-item:first-child{
                width: 65%;
            }
            .ant-form-item:nth-child(2){
                width: 20%;
            }
            .ant-form-item:nth-child(3){
                width: 100%;
            }
        }
    }

    .pagination-space{
        width: 100%;
        margin-top: 24px;
        justify-content: flex-end ;
    }
    
`;

export const ConnectButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content:  center;

  .ant-btn{
    margin-top: 20vh;

  }
`;
