import { ActionOption } from "../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/state";
import { useEffect } from "react";
import {
  updateUser,
  deleteUser,
  getAllUsers,
  getUser,
  getUserPastProject,
  createUser,
} from "../../redux/actions";
import { UserNamespace } from "../../_shared/namespaces/user";
import { ProjectNamespace } from "../../_shared/namespaces/project";

interface UseUser {
  user: UserNamespace.User;
  allUsers: UserNamespace.User[];
  pastProjects: ProjectNamespace.Project[];
  handleCreate: (payload: Record<string, any>, options?: ActionOption) => void;
  handleUpdate: (
    payload: Record<string, any>,
    id: string,
    options?: ActionOption
  ) => void;
  handleGetAll: (options?: ActionOption) => void;
  handleDelete: (id: string, options?: ActionOption) => void;
  handleGet: (id: string, options?: ActionOption) => void;
  handleGetPastProjects: (
    id: string,
    suffix: string,
    options?: ActionOption
  ) => void;
}

interface UseUserProps {
  key: string;
  autoFetch?: boolean;
  options?: ActionOption;
}

export const useUser = (parameter: UseUserProps): UseUser => {
  const { key, autoFetch = false, options: defaultOptions = {} } = parameter;

  const dispatch = useDispatch();

  const { user, allUsers, pastProjects } = useSelector((state: RootState) => {
    return {
      user: state.user.user[key] ?? {},
      pastProjects: state.user.user_past_project[key] ?? {},
      allUsers: state.user.allUsers[key] ?? [],
    };
  });

  const handleCreate = (
    payload: Record<string, any>,
    options?: ActionOption
  ) => {
    dispatch(
      createUser(payload, {
        ...defaultOptions,
        ...options,
        key: key,
      })
    );
  };

  const handleUpdate = (
    payload: Record<string, any>,
    id: string,
    options?: ActionOption
  ) => {
    dispatch(
      updateUser(payload, id, {
        ...defaultOptions,
        ...options,
        key: key,
      })
    );
  };

  const handleDelete = (id: string, options?: ActionOption) => {
    dispatch(
      deleteUser(id, {
        ...defaultOptions,
        ...options,
        key: key,
      })
    );
  };

  const handleGetAll = (options?: ActionOption) => {
    dispatch(
      getAllUsers({
        ...defaultOptions,
        ...options,
        key: key,
      })
    );
  };

  const handleGet = (id: string, options?: ActionOption) => {
    dispatch(
      getUser(id, {
        ...defaultOptions,
        ...options,
        key: key,
      })
    );
  };

  const handleGetPastProjects = (
    id: string,
    suffix: string,
    options?: ActionOption
  ) => {
    dispatch(
      getUserPastProject(id, suffix, {
        ...defaultOptions,
        ...options,
        key: key,
      })
    );
  };

  useEffect(() => {
    if (autoFetch) {
      handleGetAll({
        key: key,
      });
    }
  }, [autoFetch]);

  return {
    user,
    allUsers,
    pastProjects,
    handleCreate,
    handleDelete,
    handleUpdate,
    handleGetAll,
    handleGet,
    handleGetPastProjects,
  };
};
