import { MainContainer, MainHeader} from "../../../components";
import { ReactNode } from "react";

interface MainLayoutProps {
  children: ReactNode | ReactNode[];
  renderClassName?: string;
}

export const MainLayoutContainer = (props: MainLayoutProps) => {
  const { children, renderClassName="__app_secondary" } = props;
  

  return (
    <MainContainer>
      <MainHeader/>
      <main className={renderClassName}>{children}</main>
    </MainContainer>
  );
};
