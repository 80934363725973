import { NewProjectSuccessComponent } from "../../components";
import { message } from "antd";

import { Container } from "./index.styled";
import { useNavigate, useParams } from "react-router-dom";

export const NewProjectSuccessContainer = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const handleBackToProjects = ()=>{
    navigate(`/projects`);
  }

  const handleVisitProjects = ()=>{
    if(id) {
      navigate(`/projects/${id}/view`);
      return;
    }
    message.error("Project Not Found")
  }
  

  return (
    <Container>
        <NewProjectSuccessComponent handleBackToProjects={handleBackToProjects} handleVisitProjects={handleVisitProjects}/>
    </Container>
  );
};
