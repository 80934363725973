import { v9, sacProxy, sacToken } from "../ABIcode";

export const REACT_APP_BASEURL = `${process.env.REACT_APP_API_URL}`;
// export const PUBLIC_BSC_TEST_SAC_PROXY_ADDRESS = `${process.env.REACT_APP_BSC_TEST_SAC_PROXY_ADDRESS}`;
// export const PUBLIC_BSC_TEST_SAC_CONTRACT_ADDRESS = ``;
export const PUBLIC_API_URL = REACT_APP_BASEURL;

export const POST = "post";
export const PUT = "put";
export const GET = "get";
export const PATCH = "patch";
export const DELETE = "delete";
export const FILE_UPLOAD_URL = `${REACT_APP_BASEURL}/api/v1/files`;

export const SIGNED_ADDRESS_KEY = "@@sacrifice-storage/signed-address";
export const SIGNED_MESSAGE = "Welcome to PulseSac";
export const THEME_STORAGE_KEY = "@@sacrifice-storage/theme";
export const THEME_STORAGE_SET_KEY = "@@sacrifice-storage/theme-set";

export const EMPTY_ADDRESS = "0x0000000000000000000000000000000000000000";

// Store: Redux
export const STORE_KEY = "@@sacrifice-storage/cache";

// ADDRESSES

export const SAC_PROXY_ADDRESS = `${process.env.REACT_APP_SAC_PROXY_ADDRESS}`;
export const SAC_CONTRACT_ADDRESS = EMPTY_ADDRESS;

export const enum ContractTypes {
  sacProxy = "SacProxy",
  sacContract = "SacContract",
  sacToken = "SacToken",
}

export const ContractMetaData: any = [
  {
    title: "SacProxy",
    abi: sacProxy,
    address: SAC_PROXY_ADDRESS,
  },
  {
    title: "SacContract",
    abi: v9,
    address: "",
  },
  {
    title: "SacToken",
    abi: sacToken,
    address: "",
  },
];

export enum APP {
  mainKey = "main",
}

export const SCORE_TYPE_OPTIONS = [
  {
    label: "Points",
    value: "POINTS",
  },
  {
    label: "Percent",
    value: "PERCENT",
  },
  {
    label: "Letter (A - F)",
    value: "GRADESCALE",
  },
  {
    label: "Collected Only",
    value: "COLLECTED",
  },
];

export enum Services {
  auth = "auth",
  class = "class",
  communication = "communication",
  classroom = "classroom",
  api = "api",
}


export const PROVIDER_KEYS = Object.freeze({
  Standards: "@@STANDARDS_CONTEXT_PROVIDER",
  Class: "@@CLASS_CONTEXT_PROVIDER",
  Auth: "@@AUTH_CONTEXT_PROVIDER",
});

export const SOCIALS = [
  {
    key: "telegram",
    label: "Telegram",
    logo: "/telegram.svg",
  },
  {
    key: "facebook",
    label: "Facebook",
    logo: "/facebook.png",
  },
  {
    key: "twitter",
    label: "Twitter",
    logo: "/twitter.svg",
  },
  {
    key: "linkedin",
    label: "LinkedIn",
    logo: "/linkedin.png",
  },
  {
    key: "instagram",
    label: "Instagram",
    logo: "/instagram.png",
  },
];

export interface NETWORKS_TYPE {
  key: string;
  label: string;
  chainId: string;
  networkName?: string;
  address?: string;
  nativeToken?: string;
  usdAddress?: string;
  rpcUrls?: string[];
  blockExplorerUrls?: string[];
  acceptedTokens?: {symbol: string; address: string}[];
}
export const NETWORKS: NETWORKS_TYPE[] = [
  {
    key: "ethereum",
    label: "Ethereum Mainnet",
    networkName: "Ethereum Mainnet",
    chainId: "1",
    nativeToken: "ETH",
    usdAddress: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    rpcUrls: ["https://api.mycryptoapi.com/eth"],
    blockExplorerUrls: ["https://etherscan.io"],
    acceptedTokens: [
      {symbol: 'USDC', address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'},
      {symbol: 'USDT', address: '0xdAC17F958D2ee523a2206206994597C13D831ec7'},
      {symbol: 'WBTC', address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599'},
      {symbol: 'HEX', address: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39'},
      {symbol: 'WETH', address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'},
    ]
  },
  {
    key: "bsc",
    label: "BSC Mainnet",
    networkName: "Binance Smart Chain Mainnet",
    chainId: "56",
    nativeToken: "BNB",
    usdAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    rpcUrls: ["https://bsc-dataseed.binance.org"],
    blockExplorerUrls: ["https://bscscan.com"],
    acceptedTokens: [
      {symbol: 'BUSD', address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'},
      {symbol: 'BTCB', address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c'},
      {symbol: 'USDT', address: '0x55d398326f99059fF775485246999027B3197955'},
      {symbol: 'WBNB', address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'},
      {symbol: 'CAKE', address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'},
    ]
  },
  {
    key: "bsc_test",
    label: "BSC Testnet",
    networkName: "Smartchain Testnet",
    chainId: "97",
    nativeToken: "BNB",
    usdAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
    blockExplorerUrls: ["https://explorer.binance.org/smart-testnet"],
    acceptedTokens: [
      {symbol: 'BUSD', address: '0xDA30eE710eFA84BeB91FD918365f5Ed1B165e5C8'},
      {symbol: 'ZCOIN', address: '0x55EC5fD08502aEeAE988fb991B81e0d001800B7B'}
    ]
  },
  {
    key: "polygon",
    label: "Polygon mainnet",
    networkName: "Polygon",
    chainId: "137",
    nativeToken: "MATIC",
    rpcUrls: ["https://polygon-rpc.com"],
    usdAddress: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    blockExplorerUrls: ["https://polygonscan.com"],
    acceptedTokens: [
      {symbol: 'USDT', address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F'},
      {symbol: 'WBTC', address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6'},
      {symbol: 'USDC', address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174'},
      {symbol: 'WETH', address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619'},
    ]
  },
  {
    key: "avax",
    label: "Avalanch",
    networkName: "Avalanch",
    chainId: "43114",
    
    nativeToken: "AVAX",
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://snowtrace.io/"],
    usdAddress: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    acceptedTokens: [
      {symbol: 'USDt', address: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7'},
      {symbol: 'USDC', address: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E'},
      {symbol: 'WBTC.e', address: '0x50b7545627a5162F82A992c33b87aDc75187B218'},
      {symbol: 'USDT.e', address: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118'},
    ]
  },
  // {
  //   key: "harmony",
  //   label: "Harmony ONE",
  //   networkName: "Harmony Mainnet",
  //   chainId: "1666600000",
  //   nativeToken: "ONE",
  //   rpcUrls: ["https://api.harmony.one"],
  //   blockExplorerUrls: ["https://explorer.harmony.one/"],
  //   usdAddress: "0x3C2B8Be99c50593081EAA2A724F0B8285F5aba8f",
  //   acceptedTokens: [
  //     {symbol: 'WONE', address: '0xcF664087a5bB0237a0BAd6742852ec6c8d69A27a'},
  //     {symbol: '1USDT', address: '0x3C2B8Be99c50593081EAA2A724F0B8285F5aba8f'},
  //     {symbol: '1USDC', address: '0x985458E523dB3d53125813eD68c274899e9DfAb4'},
  //     {symbol: '1ETH', address: '0x6983D1E6DEf3690C4d616b13597A09e6193EA013'},
  //   ]
  // },
  // {
  //   key: "pulse",
  //   label: "Pulse",
  //   networkName: "pulse",
  //   chainId: "369",
  //   nativeToken: "PLS",
  // },
];

/**App routes **/
export const APP_URL = {
  project: {
    create: "/projects",
    get: "/projects",
    fetchAll: "/projects",
    update: "/projects",
    delete: "/projects",
  },
  user: {
    create: "/users",
    get: "/users",
    fetchAll: "/users",
    update: "/users",
    delete: "/users",
  },
  whitelist: {
    create: "/whitelists",
    get: "/whitelists",
    fetchAll: "/whitelists",
    update: "/whitelists",
    delete: "/whitelists",
  },
};
