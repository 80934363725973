import { Button, Dropdown, Form, Input, Menu, Space, Typography } from "antd";
import { DistributeTokenWrapper, FormWrapper } from "./index.styled";
const {Text} = Typography;

interface DistributeTokenComponentProps{
    handleClaimPercentage : ()=>void;
    onFinish? : ()=>void;
    distributedTokenLoaded?: boolean
}
export const DistributeTokenComponent = (props: DistributeTokenComponentProps) => {
    const {handleClaimPercentage, onFinish, distributedTokenLoaded} = props;
    const menu = (
        <Menu
          items={[
            {
              key: '1',
              label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                  1st menu item
                </a>
              ),
            },
            {
              key: '2',
              label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                  2nd menu item
                </a>
              ),
            },
            {
              key: '3',
              label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                  3rd menu item
                </a>
              ),
            },
          ]}
        />
      );

    return (
          <DistributeTokenWrapper>
            <Space className="top-bar" align="center">
                <Dropdown overlay={menu} placement="bottom" >
                    <Button className="eth-btn" type="primary">
                        <Space>
                            <span>Ethereum</span>
                            <img src="/caret-double.svg" alt="..." />
                        </Space>
                    </Button>
                </Dropdown>
                <Button shape="round" onClick={handleClaimPercentage}>
                  View Vesting Schedule
                </Button>
            </Space>
            {!distributedTokenLoaded && <FormWrapper
                name="distribution_token"
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
                >
                <Form.Item
                    label="Token Address"
                    name="token_address"
                    requiredMark={'optional'}
                    rules={[{ required: true, message: 'Please input token address!' }]}
                >
                    <Input placeholder="Enter Contact Address here" />
                </Form.Item>
                <Form.Item
                    label="Token Quantity"
                    name="token_quantity"
                    requiredMark={'optional'}
                    rules={[{ required: true, message: 'Please input token quantity!' }]}
                >
                    <Input  placeholder="Enter Token Quantity" />
                </Form.Item>
                <Form.Item>
                    <Button shape="round" className="submit-btn" type="primary" htmlType="submit">
                      Start Distribution
                    </Button>
                </Form.Item>
            </FormWrapper>}
            {distributedTokenLoaded && 
            <Space className="space-wrapper" direction="vertical" size={24}>
              
                <Space className="list-item-row">
                    <Text>Token Address</Text>
                    <Text>0x9289hh237xbw8q9x7wjis9d</Text>
                </Space>

                <Space className="list-item-row">
                    <Text>Token Quantity</Text>
                    <Text>100</Text>
                </Space>

                <Space className="list-item-row">
                    <Text>Total Claimed</Text>
                    <Text>892809802902 REM</Text>
                </Space>

                <Space className="list-item-row">
                    <Text>Total Left</Text>
                    <Text>892809802 REM</Text>
                </Space>
                
            </Space>}
          </DistributeTokenWrapper>
    );
};