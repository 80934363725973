import {  Button, Divider, Space, Tabs, Typography } from "antd";
import { useState } from "react";
import { ConnectButton } from "../project/connect-button";
import { NewProjectWrapper } from "./index.styled";
import { FormOneComponent, FormTwoComponent, FormThreeComponent } from "./lib/form";
import { StepComponent } from "./lib/steps";
const { Text } = Typography;
const { TabPane } = Tabs;

interface NewProjectComponentProps  {
  // length: number;
  // currentIndex: number;
  onComplete?: ((values?: any) => void) | undefined
  handleBackArrow: ((values?: any) => void)
  _OnSubmitFormOne: ((values?: any) => void) 
  _OnSubmitFormTwo: ((values?: any) => void) 
  loading: boolean
  loadingConnect: boolean
  address?: string
  handleConnect: ()=>void
  totalPhaseDays: number
  
}


export const NewProjectComponent = (props: NewProjectComponentProps) => {
  const {onComplete, handleBackArrow, _OnSubmitFormOne, _OnSubmitFormTwo, 
          loadingConnect, loading, address, totalPhaseDays, handleConnect} = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const onSubmitFormOne =  (data: any)=>{
    setCurrentIndex(1);
    _OnSubmitFormOne(data);
  }

  const onSubmitFormTwo =  (data: any)=>{
    setCurrentIndex(2);
    _OnSubmitFormTwo(data)
  }

  const onSubmitFormComplete =  (data: any)=>{
    if(onComplete)onComplete(data);
  }


  const handleBackButton = () =>{
    if(currentIndex > 0) {
      setCurrentIndex(currentIndex-1);
    }else{
      handleBackArrow()
    }
  }
  return (
        <NewProjectWrapper>
          <Space className="main-container" direction="vertical">
            <Space className="header-row" align="center" >
              <Button onClick={handleBackButton} icon={
                <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.5 7H20.5M1.5 7L7.54545 1.5M1.5 7L7.54545 12.5" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>
              }/>
            </Space>
            <Space className="title-row" align="center" >
              <Text className="title-text">New Sacrifice Project</Text>
              <StepComponent length={3} currentIndex={currentIndex}/>
            </Space>
            <Divider className="header-divider"/>
            {address && <Tabs activeKey={currentIndex+''} renderTabBar={()=><></>} >
              <TabPane tab={null} key="0">
                <Space className="form-container">
                  <FormOneComponent onFinish={onSubmitFormOne}/>
                </Space>
              </TabPane>
              <TabPane tab={null} key="1">
                <Space className="form-container">
                  <FormTwoComponent 
                    onFinish={onSubmitFormTwo}
                    handlePrevious={handleBackButton}
                    />
                </Space>
              </TabPane>
              <TabPane tab={null} key="2">
                <Space className="form-container">
                    <FormThreeComponent
                      onFinish={onSubmitFormComplete}
                      handlePrevious={handleBackButton}
                      loading={loading}
                      totalPhaseDays={totalPhaseDays}
                      />
                  </Space>
              </TabPane>
            </Tabs>}
            {!address &&<ConnectButton loading={loadingConnect} handleConnect={handleConnect}/>}
            

          </Space>
        </NewProjectWrapper>
  );
};