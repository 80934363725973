import { DeleteOutlined, PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button,  Divider, Form, Input, message, notification, Select, Space, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { useState } from "react";
import { REACT_APP_BASEURL, SOCIALS } from "../../../../../_shared";
import { FormWrapper } from "../index.styled";
const { Option } = Select;


const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
};


interface FormOneComponentProps  {
    // length: number;
    // currentIndex: number;
    onFinish?: ((values: any) => void) | undefined
}

export const FormOneComponent = (props: FormOneComponentProps) => {
    const {onFinish} = props;
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();
    const [form] = Form.useForm();

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'|| file.type === 'image/gif';
        if (!isJpgOrPng) {
          setImageUrl('');
          notification.error({
            key: `isJpgOrPng`,
            message: 'You can only upload JPG/PNG file',
            placement: 'bottomLeft'

        })
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            notification.error({
                key: `isLt2M`,
                message: 'Image must smaller than 2MB',
                placement: 'bottomLeft'

            })
            setImageUrl('');
        }
        return isJpgOrPng && isLt2M;
      };
    const normFile = (e: any) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        if (e?.file?.status === 'done') {
            // Get this url from response in real world.
            getBase64(e?.file?.originFileObj as RcFile, url => {
              setLoading(false);
              setImageUrl(url);
            });
          }
        if (e?.file?.status === 'uploading') {
            setLoading(true);
        }
        return e?.file?.response?.data?.url;
    };
    // useEffect(()=> {
    //     console.log('imag', imageUrl);
    //     form.setFieldsValue({ logo: imageUrl })}, [imageUrl]);

    const uploadButton = (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      );
    return (
            <FormWrapper>
                <Form
                form={form}
                    name="form_one"
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                    initialValues={{ socials: [{name: 'telegram', url: ''}] }}
                    >
                    <Form.Item
                        label="Project Title"
                        name="title"
                        requiredMark={'optional'}
                        rules={[{ required: true,message: 'Please input your project title!' }]}
                    >
                        <Input placeholder="e.g GameBoy" />
                    </Form.Item>
                    <Form.Item
                        label="Website URL (Must begin with http:// or https://)"
                        name="website"
                        requiredMark={'optional'}
                        validateTrigger="onBlur"
                        rules={[{ required: true,  type:'url',message: 'Invalid url' }]}
                    >
                        <Input placeholder="" />
                    </Form.Item>
                    <Form.Item
                            name="logo"
                            label="Logo"
                            requiredMark={'optional'}
                            getValueFromEvent={normFile}
                            rules={[{ required: true,  type:'url', message: 'Please upload your project logo!' }]}
                        >
                        <Upload
                            name="file"
                            disabled={loading}
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={`${REACT_APP_BASEURL}/media`}
                            beforeUpload={beforeUpload}
                            >
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ height: '100%'}} /> : uploadButton}
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        name="description"
                        label="Description"
                        requiredMark={'optional'}
                        rules={[{ required: true, message: 'Please input Description' }]}
                    >
                        <Input.TextArea rows={4} showCount maxLength={80} />
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <span className="ant-form-text">Social Media</span>
                        </Space>
                        
                    </Form.Item>
                    
                    <Form.List
                        name="socials"
                        rules={[
                        {
                            validator: async (_, links) => {
                            if (!links || links.length < 1) {
                                return Promise.reject(new Error('At least 1 Social Media'));
                            }
                            },
                        },
                        ]}
                    >
                        {(fields, { add, remove }, { errors }) => (
                            
                        <>
                            {fields.map((field, index) => (
                                <Space key={field.key +index} className="social-media-items" align="center" >
                                    
                                    <Form.Item
                                    {...field}
                                    label="Name"
                                    key={'name' +index}
                                    name={[field.name, 'name']}
                                    requiredMark={'optional'}
                                    rules={[{ required: true, message: 'Missing Social Media' }]}
                                    >
                                    <Select
                                        placeholder="Select a social media"
                                        allowClear
                                    >
                                        {SOCIALS.map((social, index)=>{
                                            return <Option key={index}  value={social.key}>{social.label}</Option>
                                        })}
                                    </Select>
                                    
                                    </Form.Item>
                                    <Form.Item
                                    {...field}
                                    label="Url (Must begin with http:// or https://)"
                                    key={'url' +index}
                                    name={[field.name, 'url']}
                                    requiredMark={'optional'}
                                    validateTrigger="onBlur"
                                    rules={[{ required: true,  type:'url', message: 'Invalid url' }]}
                                    >
                                    <Input placeholder="" />
                                    </Form.Item>
                    
                                    {fields.length > 1 ? (
                                        <DeleteOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                        />
                                        ) : null}
                                </Space>
                            ))}
                            <Form.Item>
                                <Button
                                    type="text"
                                    onClick={() => add()}
                                    style={{ marginLeft: 'auto' }}
                                    icon={<PlusOutlined />}
                                >
                                    Add Another Social
                                </Button>
                            </Form.Item>
                            <div>
                                <Form.ErrorList errors={errors} />
                            </div>
                        </>
                        )}
                    </Form.List>

                    <Divider />

                    

                    <Form.Item>
                        <Button shape="round" className="next-btn" type="primary" htmlType="submit">
                            Next - Sacrifice Settings
                        </Button>
                    </Form.Item>
                </Form>
            </FormWrapper>
    );
  };    