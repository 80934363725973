import styled from "styled-components";

export const StyledHeader = styled.header`
    display: flex!important;
    align-items: center;
    justify-content: space-between;
    padding-left: 80px !important;
    padding-right: 80px !important;
    box-shadow: 0px 1px 0px var(--border);
    height: 104px;
    /* overflow-x: scroll; */
    
    .logo-container{
        display: flex;
        gap: 16px;
        align-items: center;
        .menu-button{
            display: none ;
        }
        .ant-typography{
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
        }
    }

    .ant-switch-checked {
        background: var(--accent)!important;
    }

    .connect-container{
        display: flex;
        align-items: center;
        gap: 24px;
        span{
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }
    }

    
    @media (min-width: 1440px) {
        /*  */
    }
    @media (max-width: 1439px) {
        /*  */
    }
    @media (max-width: 768px) {
        /*  */
    }
    @media (max-width: 425px) {
        padding-left: 16px!important ;
        padding-right: 16px!important;
        /* .connect-container .connect-wallet, .disconnect-wallet, .__logo-text{
            display: none !important;
        } */
        .connect-container .create-project, .__logo-text{
            display: none !important;
        }
        .logo-container .menu-button{
            display: block ;
        }
    }
    
`;