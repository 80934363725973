import { navigate } from '@reach/router';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
// import { ReactComponent as Icon } from '../../app/assets/images/404-Icon.svg';

const Page404 = () => {
  const { t } = useTranslation();
  return (
    <div className={'stm-page-container stm-flex-center'}>
      <>Icon</>
      <div className={'stm-text-center'}>
        <div className={'stm-fw-5 stm-fs-text-5 stm-mb-3 stm-mt-5'}>
          {t('pageNotFound')}
        </div>
        <div className={'stm-fs-text-4 stm-text-color-secondary stm-mb-12'}>
          The page you are looking for does not exist.
        </div>
        <Button type={'primary'} size={'large'} onClick={() => navigate(-1)}>
          <span className={'stm-ph-8 stm-fw-3'}>Go Back</span>
        </Button>
      </div>
    </div>
  );
};
export default Page404;
