import styled from "styled-components";

export const ManageNetworkWrapper = styled.div`
    display: flex!important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap:0px;

    .top-bar{
        margin-top: 40px;
        width: 100%;
        justify-content: space-between;
    }
    .ant-btn{
        font-weight: 400!important;
        font-size: 16px!important;
        line-height: 24px!important;
    }
    .message-row{
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;

        margin-top: 80px;
        margin-bottom: 40px;
    }

    .sub-message-row{
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        color: var(--disabled-color)!important;
    }
    .eth-btn{
        background-color: #3C3CC7!important;
    }
    .deploy-btn{
        margin-top: 16px;
        margin-bottom: 120px;
        background-color: var(--success-color)!important;
        border-color: var(--success-color)!important;
    }
    .ant-table-wrapper{
        width: 100%;
    }
    /* var(--success-color) */
`;
