import styled from "styled-components";

export const Container = styled.footer`
    position: relative;
    width: 100%;
    padding: 230px 120px 64px 120px ;
    background-color: var(--landing-background-2);
    .main-container{
        width: 100%;
        justify-content: space-between ;
    }

    .logo-container{
        .ant-typography{
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
        }
    }

    .link-container{
        a{
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: var(--text-color)!important;
        }
    }

   
    
    @media (min-width: 1440px) {
        /*  */
    }
    @media (max-width: 1439px) {
        /*  */
    }
    @media (max-width: 768px) {
        padding-left: 16px!important ;
        padding-right: 16px!important;
    }
    @media (max-width: 425px) {
        padding-left: 16px!important ;
        padding-right: 16px!important;
        .main-container, .link-container{
            align-items: flex-start!important;
            flex-direction: column;
            gap: 32px!important;
        }
        .main-container{
            gap: 32px!important;
        }
        .link-container{
            gap: 24px!important;
        }
    }
    
`;