import styled from "styled-components";

export const StyledHeader = styled.header`
    /* background: var(--landing-background); */
    display: flex!important;
    align-items: center;
    justify-content: space-between;
    padding-left: 120px !important;
    padding-right: 120px !important;
    height: 104px;
    
    .logo-container{
        display: flex;
        gap: 8px;
        align-items: center;
        .menu-button{
            display: none ;
        }
        .ant-typography{
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
        }
    }

    .ant-switch-checked {
        background: var(--landing-background-inverse)!important;
    }

    .connect-wallet{
        background: var(--landing-background)!important;
        color: var(--landing-background-inverse)!important;
        border: 1px solid var(--landing-background-inverse)!important;
    }

    .connect-container{
        display: flex;
        align-items: center;
        gap: 24px;
        a{
            color: var(--icon-color-hover)
        }
    }

    
    @media (min-width: 1440px) {
        /*  */
    }
    @media (max-width: 1439px) {
        /*  */
    }
    @media (max-width: 768px) {
        padding-left: 16px!important ;
        padding-right: 16px!important;
        .connect-container  .link-container{
            display: none!important;
        }
        .logo-container .menu-button{
            /* display: block ; */
        }
        .connect-container .ant-switch{
            display: none!important;
        }
    }
    @media (max-width: 425px) {
        padding-left: 16px!important ;
        padding-right: 16px!important;
        .connect-container .link-container{
            display: none!important;
        }
        .logo-container .menu-button{
            /* display: block ; */
        }
        .connect-container .ant-switch{
            display: none!important;
        }
    }
    
`;