
import { GlobalOutlined} from "@ant-design/icons";
import {  Avatar, Col, Divider,  Row, Space, Typography } from "antd";
import { SOCIALS } from "../../../../_shared";
import { ProjectNamespace } from "../../../../_shared/namespaces/project";
import { CoverContainer } from "./index.styled";
const { Text } = Typography;


interface ProjectDetailsProps {
  project?: ProjectNamespace.Project;
}
export const ProjectDetails = (props: ProjectDetailsProps) => {
    const {project:_project} = props;
    const project = _project??{} as ProjectNamespace.Project;

    const vestingSchedule = project.vestingSchedule??[];
    const socials = project.socials??[];
    const renderVestingSchedule = vestingSchedule.map((schedule, index:number)=>{
      
      return  <Row justify="space-between" key={index}>
                <Col>
                  <Text>{(new Date(schedule.timestamp * 1000)).toDateString()}</Text>
                </Col>
                <Col>
                  <Text >{schedule.percentage}</Text>
                </Col>
              </Row>

    })

    const renderSocials = socials.map((social, index:number)=>{
      const imgUrl = SOCIALS.find((_s)=>{
        return _s.key === social.name;
      })
      return  (<a key={index} href={social.url} target="_blank" rel="noreferrer" >
                <Avatar size="small" src={imgUrl?.logo} icon={<GlobalOutlined />} />
            </a>)

    })

    return (
          <CoverContainer id="details">
              <Row justify="space-between">
                <Col>
                  <Text className="title-text">Project Details</Text>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col>
                  <Text className="disable">{project.description} </Text>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col>
                  <Text className="disable">KYC</Text>
                </Col>
                <Col>
               {Boolean(project.isKYCed) ? <img src="/verified.svg" alt="...." /> : <img src="/not-verified.svg" alt="...." />}
                </Col>
              </Row>
              <Row justify="space-between">
                <Col>
                  <Text className="disable">Socials</Text>
                </Col>
                <Col>
                {renderSocials}
                </Col>
              </Row>
              
              
              <Divider />
              <Row justify="space-between">
                <Col>
                  <Text className="title-text">Vesting Schedule</Text>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col>
                  <Text className="disable">Day</Text>
                </Col>
                <Col>
                  <Text className="disable">Percentage Claimable (%)</Text>
                </Col>
              </Row>
              {renderVestingSchedule}
                          
          </CoverContainer>
    );
};