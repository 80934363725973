import { Button, Form, Input, Select, SelectProps } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { ModalWrapper } from "../index.styled";
import { FormWrapper } from "./index.styled";

interface NewGroupModalProps{
    visible: boolean
    onFinish?: ((values: any) => void) | undefined
    onClose?: () => void
}

export const NewGroupModal = (props: NewGroupModalProps) => {
    const {visible, onFinish, onClose} = props;
    const [options, setOptions] = useState<SelectProps['options']>([]);
    const [form] = useForm()
    

    const handleKeyStroke = (e: any) => {
        
        if(e.code !== "Enter")return;
        let value : string = (e.target as any).value ??"";
        if(!options?.find(option=>option.value == value)) {
            setOptions(old=>[...(old as any), {
            label: value,
            value,
        }]);
            
            
            
        }
        
        
        
    };
   
    return (
          <ModalWrapper  
                title="Add New Group" 
                footer={null}
                visible={visible}
                onCancel={onClose}
                >
            
            <FormWrapper
                name="new_group"
                layout="vertical"
                form={form}
                initialValues={{ remember: true }}
                onFinish={(data:any)=>{
                    onFinish?.(data)
                    form.resetFields();
                }}
                autoComplete="off"
                >
                

                    <Form.Item
                        label="Group Name"
                        name="name"
                        requiredMark={'optional'}
                        rules={[{ required: true, message: 'Please input name!' }]}
                    >
                        <Input placeholder="Enter Group Name" />
                    </Form.Item>

                    <Form.Item
                        label="Bonus Percentage"
                        name="percentage"
                        requiredMark={'optional'}
                        rules={[{ required: true, message: 'Please input percentage!' }]}
                    >
                        <Input type="number" placeholder="e.g 10" suffix={"%"}/>
                    </Form.Item>

                    {/* <Form.Item
                        label="Addresses"
                        name="addresses"
                        requiredMark={'optional'}
                        rules={[{ required: true, message: 'Please input percentage!' }]}
                    >
                        <TextArea placeholder="Seperate addresses with a comma" rows={5} />
                    </Form.Item> */}

                    <Form.Item
                        label="Addresses"
                        name="addresses"
                        requiredMark={'optional'}
                        rules={[{ required: true, message: 'Please input addresses!' }]}
                    >
                        <TextArea placeholder="Seperate addresses with a comma" rows={5} />
                    </Form.Item>

                <Form.Item>
                    <Button shape="round" className="submit-btn" type="primary" htmlType="submit">
                        Add Group
                    </Button>
                </Form.Item>
                </FormWrapper>
          </ModalWrapper>
    );
};